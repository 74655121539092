<template>
  <div class="visonic visonic-app">
    <button class="visonic-back" @click="$router.go(-1)">
      <i class="material-icons">west</i>
      <p>Powrót</p>
    </button>
    <div class="flex">
    <header class="products">
      <div class="container">
        <div class="img"></div>
        <h4>Aplikacja visonic alert</h4>
      </div>
    </header>
    <section class="products">
      <header>
        <img src="../../../public/img/webp/visonic_app.webp" alt="Aplikacja visonic alert">
      </header>
      <article
        v-for="(product, index) in products"
        :key="'product' + index"
      >
        <div class="content">
          <h5>{{ product.name }}</h5>
          <p v-html="$decodeHTML($sanitize(product.description))"></p>
          <div class="img-wrapper">
            <img src="../../../public/pliki/visionicAlert/001.png" alt="001">
            <img src="../../../public/pliki/visionicAlert/002.png" alt="002">
            <img src="../../../public/pliki/visionicAlert/003.png" alt="003">
            <img src="../../../public/pliki/visionicAlert/004.png" alt="004">
            <img src="../../../public/pliki/visionicAlert/005.png" alt="005">
            <img src="../../../public/pliki/visionicAlert/006.png" alt="006">
            <img src="../../../public/pliki/visionicAlert/007.png" alt="007">
            <img src="../../../public/pliki/visionicAlert/008.png" alt="008">
            <img src="../../../public/pliki/visionicAlert/009.png" alt="009">
          </div>
        </div>
      </article>
    </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IntrusionDetectors',
  data() {
    return {
      products: [],
    }
  },
  computed: {
    api() {
      return this.$store.state.api;
    },
  },
  async created(){
    await this.getProducts();
  },
  methods: {
    async getProducts() {
      let self = this;
      await this.axios.get(this.api + "product/client", { params: { categories: ['60f560da5b36c1bab4d1eb57'] } } ).then((response) => {
        if (response.data.status == 1) {
          self.products = response.data.data;
        }
      });
    },
  },
}
</script>

<style lang="scss" scoped>
  div.img { background: url('../../../public/img/icon_visonic_7.png') no-repeat 50% 50%; }
  section.products { margin: 0 0 0 20px; }
  section.products header { margin-top: 20px; }
  section.products article div.content { width: 1200px; margin: 0; }
  section.products article div.content div.img-wrapper { display: flex; justify-content: space-between; flex-wrap: wrap; }
  section.products article div.content div.img-wrapper img { margin: 0; width: 30%; }
  @media (max-width: 1240px) {
    section.products article div.content { width: calc(100vw - 40px); }
    section.products header { margin-top: 0; }
  }
  @media (max-width: 800px) {
    section.products article div.content div.img-wrapper img { width: 45%; }
  }
  @media (max-width: 500px) {
    section.products article div.content div.img-wrapper { justify-content: center; }
    section.products article div.content div.img-wrapper img { width: 90%; }
  }
</style>
