<template>
  <div class="popup" v-if="buttonData.visible">
    <div class="shadow" @click="close()"></div>
    <div class="container">
      <div class="wrapper">
        <h4>{{ buttonData.title }}<br /></h4>
        <h5>{{ buttonData.text }}</h5>
        <div class="flex">
          <button
            :class="`admin-default ${buttonData.buttonLeft.color}`"
            @click="$emit('leftButtonClick')"
          >
            Anuluj
          </button>
          <button 
            :class="`admin-default ${buttonData.buttonRight.color}`"
            @click="$emit('rightButtonClick')"
          >
            Usuń
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TwoButtonsPopup",
  data() {
    return {};
  },
  props: {
    buttonData: Object,
  },
  computed: {
    api() {
      return this.$store.state.api;
    },
  },
  methods: {
    close() {
      this.buttonData.visible = 0;
    },
  },
};
</script>
<style scoped lang="scss">
div.popup {
  position: absolute;
  top: 0;
  left: 0;
}
div.popup div.shadow {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.5);
}
div.popup div.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.popup div.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border: 1px solid $adminGrayLight;
  padding: 20px;
  position: relative;
}
div.popup div.wrapper h4 {
  margin: 15px 0;
  padding: 0;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
}
div.popup div.wrapper h5 {
  margin: 15px 0;
  padding: 0;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  color: gray;
}
div.popup div.wrapper div.flex {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
div.popup div.wrapper button {
  margin: 15px 0 30px 0;
}
</style>
