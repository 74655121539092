export default {
    computed: {
        _content() {
            return this.$store.state.content;
        },
    },
    methods: {
        $getPageIndex(page) {
            return this._content.findIndex(x => x.page === page);
        },
        $getSectionIndex(page, section) {
            return this._content[this.$getPageIndex(page)].sections.findIndex(x => x.section === section);
        },
        $getElementIndex(page, sectionIndex, element) {
            return this._content[this.$getPageIndex(page)].sections[sectionIndex].elements.findIndex(x => x.name === element);
        },
        $getDataIndex(page, sectionIndex, elementIndex, data) {
            return this._content[this.$getPageIndex(page)].sections[sectionIndex].elements[elementIndex].data.findIndex(x => x.name === data);
        },
        $getContent(page, section, element, data) {
            return this._content[this.$getPageIndex(page)]
                    .sections[this.$getSectionIndex(page, section)]
                    .elements[this.$getElementIndex(page, this.$getSectionIndex(page, section), element)]
                    .data[this.$getDataIndex(page, this.$getSectionIndex(page, section), this.$getElementIndex(page, this.$getSectionIndex(page, section), element), data)].content;
        },
        $decodeHTML (html) {
            let txt = document.createElement('textarea');
            txt.innerHTML = html;
            return txt.value;
        }
    },
};
