<template>
  <div class="admin-blog-post-container admin-box" @click="edit(data._id)">
    <div class="thumbnail">
      <img :src="api + data.photo" alt="Thumbnail" />
    </div>
    <h5>{{ data.name }}</h5>
  </div>
</template>

<script>
export default {
  name: "AdminProduct",
  data() {
    return {};
  },
  computed: {
    api() {
      return this.$store.state.api;
    },
  },
  props: {
    data: Object,
  },
  methods: {
    edit(id) {
      this.$store.commit("changeBlogEditId", id);
      this.$emit("selectedToEdit", id);
    },
  },
};
</script>
<style scoped lang="scss">
div.admin-blog-post-container {
  width: 340px;
  margin: 20px;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
div.thumbnail {
  display: flex;
  justify-content: center;
  width: 300px;
  height: 200px;
  margin: 20px auto;
}
div.thumbnail img {
  border-radius: 3px;
}
div.thumbnail div.visibility {
  position: absolute;
  top: 5px;
  right: 5px;
}
h5 {
  margin: 20px;
  margin-bottom: 17px;
  padding: 0;
  font-weight: 600;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.7);
  text-overflow: ellipsis;
  overflow: hidden;
}
h6 {
  margin: 10px 20px;
  padding: 0;
  font-weight: 300;
  font-size: 13px;
  color: $adminGrayLight;
  text-align: right;
}
ul.categories {
  display: flex;
  margin: 17px;
  margin-top: 0;
  padding: 0;
  bottom: 0;
  right: 0;
  list-style: none;
  flex-wrap: wrap;
}
ul.categories li {
  margin: 3px;
  padding: 3px 10px;
  border-radius: 3px;
  font-size: 12px;
  color: $adminTealLight;
  background: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}
ul.tags {
  display: flex;
  margin: 17px;
  padding: 0;
  list-style: none;
  color: #fff;
  flex-wrap: wrap;
}
ul.tags li {
  margin: 3px;
  padding: 4px 10px;
  border-radius: 15px;
  background: $adminGrayLight;
  font-size: 12px;
}
h6 span {
  color: $adminGrayDark;
}
div.admin-blog-post-container:hover {
  background: $adminTealLight;
  cursor: pointer;
}
div.admin-blog-post-container:hover h5 {
  color: #fff;
}
div.admin-blog-post-container:hover h6 {
  color: rgba(255, 255, 255, 0.8);
}
div.admin-blog-post-container:hover h6 span {
  color: rgba(255, 255, 255, 1);
}
div.admin-blog-post-container:hover ul.tags li {
  background: rgba(255, 255, 255, 0.5);
  color: rgba(0, 0, 0, 0.7);
}
</style>
