<template>
<div>
  <div class="element">
    <header :class="{ 'admin-teal': !errors.name, 'admin-red': errors.name }">
      <h4>Nazwa</h4>
    </header>
    <input class="admin-default2" type="text" v-model="editProduct.name">
    <p class="error">{{ errors.name }}</p>
  </div>
  <div class="element">
    <header :class="{ 'admin-teal': !errors.description, 'admin-red': errors.description }">
      <h4>Opis produktu</h4>
    </header>
    <div class="editor">
      <vue-editor :editor-toolbar="editorToolbars['post']" v-model="editProduct.description"/>
    </div>
    <p class="error">{{ errors.description }}</p>
  </div>
  <div class="element img">
    <header class="admin-teal">
      <h4>Aktualne zdjęcie</h4>
    </header>
    <div class="thumbnail">
      <img :src="api+actualImg" alt="Thumbnail" v-if="!editProduct.photo">
      <img :src="editProduct.photoView" alt="Thumbnail" v-if="editProduct.photo">
    </div>
  </div>
  <div class="element flex">
    <button :class="{ 'admin-default teal': !errors.photo, 'admin-default red': errors.photo }" @click="clickUpload()">Zmień zdjęcie</button>
    <input id="file" name="attachment" type="file" ref="file" style="display: none" @change="handleFileUpload()">
    <button class="admin-default teal" v-if="file != 0" @click="removeImgFile()">Przywróc aktualne zdjęcie</button>
    <div class="photo" v-if="file != 0">
      <h6>Wybrane zdjęcie: </h6>
      <p>{{ file[0].name }}</p>
    </div>
    <div class="photo" v-if="file != 0">
      <h6>Rozmiar: </h6>
      <p>{{ getMB(file[0].size) }}</p>
    </div>
  </div>
  <p class="error">{{ errors.photo }}</p>
  <div class="element">
    <header :class="{ 'admin-teal': !errors.manual, 'admin-red': errors.manual }">
      <h4>Nazwa pliku z instrukcją (razem z rozszerzeniem)</h4>
      <h5>Plik należy umieścić na serwerze w folderze x poprzez ftp</h5>
    </header>
    <input class="admin-default2" type="text" v-model="editProduct.manual">
    <p class="error">{{ errors.manual }}</p>
  </div>
  <div class="element">
    <header :class="{ 'admin-teal': !errors.catalog, 'admin-red': errors.catalog }">
      <h4>Nazwa pliku z kartą katalogową (razem z rozszerzeniem)</h4>
      <h5>Plik należy umieścić na serwerze w folderze x poprzez ftp</h5>
    </header>
    <input class="admin-default2" type="text" v-model="editProduct.catalog">
    <p class="error">{{ errors.catalog }}</p>
  </div>
  <div class="element element-category">
    <h6>Kategorie</h6>
    <div class="categories">
      <div class="category" :class="{ checked: category.checked == 1 }" v-for="(category, index) in categories" :key="index" @click="checkCategory(index)">
        <p>{{ category.name }}</p>
        <i class="material-icons">{{ getCategoryIcon(index) }}</i>
      </div>
    </div>
    <p class="error">{{ errors.categories }}</p>
  </div>
  <div class="nav-flex">
    <button class="admin-default teal" @click="updateProduct()">zapisz</button>
    <button class="admin-default red" @click="buttonData.visible = 1">Usuń</button>
  </div>
  <two-buttons-popup
    v-bind:buttonData="buttonData"
    @leftButtonClick="buttonData.visible = 0"
    @rightButtonClick="removeProduct()"
  ></two-buttons-popup>
</div>
</template>

<script>
import roundTo from 'round-to';
import TwoButtonsPopup from '@/components/popups/TwoButtonsPopup.vue';

export default {
  name: 'EditProduct',
  components: {
    TwoButtonsPopup,
  },
  data() {
    return {
      file: 0,
      formData: 0,
      categories: [],
      editProduct: {
        name: '',
        description: '',
        manual: '',
        catalog: '',
        categories: [],
      },
      errors: {
        name: '',
        description: '',
        photo: '',
        manual: '',
        catalog: '',
        categories: '',
      },
      actualImg: '',
      productId: this.$route.params.productId,
      buttonData: {
        visible: 0,
        name: 'Czy na pewno chcesz usunąć produkt?',
        text: 'Ta czynność jest nieodwracalna.',
        buttonLeft: {
          text: 'Anuluj',
          color: 'teal',
        },
        buttonRight: {
          text: 'Usuń',
          color: 'red',
        },
      },
    }
  },
  computed:{
    api(){
      return this.$store.state.api;
    },
    editorToolbars(){
      return this.$store.state.editorToolbars;
    },
  },
  async created(){
    this.getCategories();
    await this.getProduct();
  },
  methods: {
    getCategories() {
      let self = this;
      this.axios
      .get(this.api + 'product/category', )
      .then(response => {
        if(response.data.status == 1){
          response.data.data.forEach(data => {
            self.categories.push({
              checked: 0,
              name: data.name,
              _id: data._id
            });
          });
        }
      });
    },
    getCategoryIcon(index){
      if(this.categories[index].checked == 1){
        return 'done'
      }else{
        return 'more_horiz'
      }
    },
    checkCategory(index){
      if(this.categories[index].checked == 1){
        const editProductIndex = this.editProduct.categories.indexOf(this.categories[index]._id);
        this.editProduct.categories.splice(editProductIndex, 1);
        this.categories[index].checked = 0;
      }else{
        this.editProduct.categories.push(this.categories[index]._id);
        this.categories[index].checked = 1;
      }
    },
    async updateProduct() {
      this.formData = new FormData();
      this.formData.append('photo', this.file[0]);
      this.formData.append('name', this.editProduct.name);
      this.formData.append('description', this.editProduct.description);
      this.formData.append('manual', this.editProduct.manual);
      this.formData.append('catalog', this.editProduct.catalog);
      for(let i = 0; i < this.editProduct.categories.length; i++) {
        this.formData.append('categories[]', this.editProduct.categories[i]);
      };
      Object.keys(this.errors).forEach(key => {
        this.errors[key] = '';
      });
      this.formData.append('_id', this.productId);
      await this.axios
      .put(this.api + 'product/', this.formData, { headers: { 'Content-type': `multipart/form-data; boundary=${ this.formData._boundary }` } })
      .then(response => {
        if(response.data.status === 1) {
          this.$store.commit('toggleAlert', {
            visibility: 1,
            type: 1,
            msg: response.data.msg
          });
          this.$router.push({ name: 'Products' });
        } else {
          if(response.data.msgs) {
            response.data.fields.forEach(field => {
            this.errors[field] = response.data.msgs[response.data.fields.indexOf(field)];
          });
          } else {
            this.$store.commit('toggleAlert', {
              visibility: 1,
              type: 0,
              msg: response.data.msg
            });
          };
        };
      });
    },
    async removeProduct() {
      const payload = { _id: this.productId };
      await this.axios
      .delete(this.api + 'product', { data: payload })
      .then(response => {
        if(response.data.status === 1) {
          this.$store.commit('toggleAlert', {
            visibility: 1,
            type: 1,
            msg: response.data.msg
          });
          this.$router.push({ name: 'Products' });
        } else {
          this.$store.commit('toggleAlert', {
            visibility: 1,
            type: 0,
            msg: response.data.msg
          });
        };
      });
    },
    async getProduct(){
      let self = this;
      await this.axios
      .get(this.api + 'product', { params: { _id: this.productId } } )
      .then(response => {
        if(response.data.status == 1){
          self.editProduct = response.data.data[0];
          let categoriesIds = [];
          response.data.data[0].categories.forEach(category => {
            categoriesIds.push(category.category._id);
          });
          self.editProduct.categories = categoriesIds;
          self.categories.forEach(category => {
            if(self.editProduct.categories.includes(category._id)) {
              category.checked = 1;
            };
          });
          self.actualImg = self.editProduct.photo;
          self.editProduct.photo = '';
        }
      });
    },
    getMB(size){
      return roundTo(size / 1000000, 3) + ' MB';
    },
    clickUpload(){
      document.getElementById('file').value = '';
      document.getElementById('file').click()
    },
    handleFileUpload(){
      this.file = this.$refs.file.files;
      this.editProduct.photo = this.file[0];
      this.editProduct.photoView = URL.createObjectURL(this.editProduct.photo);
    },
    removeImgFile(){
      this.file = 0;
      this.editProduct.photo = '';
      this.editProduct.photoView = '';
    },
  },
}
</script>

<style lang="scss" scoped>
  div.editor::v-deep .ql-container .ql-editor h5 { font-size: 24px; }
  div.editor::v-deep .ql-container .ql-editor h6 { font-size: 19px; }
  div.editor::v-deep .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before{font-size: 24px}
  div.editor::v-deep .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before{font-size: 19px}
  div.editor::v-deep ul[data-checked="true"] { padding: 0 0 0 20px; list-style: none; }
  div.editor::v-deep ul[data-checked="true"] li:before { content: '✓'; margin-right: 10px; }
  div.editor::v-deep ul[data-checked="false"] { padding: 0 0 0 20px; list-style: none; }
  div.editor::v-deep ul[data-checked="false"] li:before { content: '✖'; margin-right: 10px; font-weight: 100; }
  div.element { margin: 20px 0; }
  div.element.flex { display: flex; align-items: center; margin: 20px 10px; flex-wrap: wrap; }
  div.element.box { margin: 20px; }
  div.element button { margin: 0 10px; }
  div.element.flex button { margin: 5px 10px; }
  div.element.flex div.photo { margin: 5px 10px; }
  div.element header { display: flex; flex-direction: column; align-items: flex-start; justify-content: center; }
  div.element header h5 { margin: 0 10px; padding: 0; font-size: 12px; font-weight: 400; color: #fff; }

  div h6 { margin: 10px; padding: 0; font-weight: 400; font-size: 14px; color: $adminGrayLight; }
  div.photo { display: flex; align-items: center; background: #fff; height: 35px; margin: 0 10px; }
  div.photo h6 { margin: 0 10px; padding: 0; font-weight: 400; font-size: 14px; color: $adminGrayLight; }
  div.photo p { margin: 0 10px 0 0; padding: 0; font-weight: 400; font-size: 14px; color: $adminBlackLight; }
  div.editor { margin: 0 20px; background: #fff; }
  div.element-category { margin: 10px; }
  div.categories { display: flex; flex-wrap: wrap; margin: 0 5px; }
  div.categories div.category { display: flex; align-items: center; height: 35px; margin: 5px; background: $adminGrayLight; border-radius: 3px; cursor: pointer; }
  div.categories div.category.checked { background: $adminGreenLight; }
  div.categories div.category p {  margin: 0 0 0 10px; padding: 0; font-size: 14px; color: #fff; }
  div.categories div.category i.material-icons { margin: 0 10px; font-size: 14px; color: #fff; }

  div.element-tag { margin: 10px; }
  div.tags { display: flex; flex-wrap: wrap; }
  div.tags div.tag { display: flex; margin: 5px; }
  div.tags div.tag div.tag-done { display: flex; align-items: center; height: 30px; border-radius: 15px; background: #fff; }
  div.tags div.tag div.tag-done p { margin: 0 0 0 10px; padding: 0; font-size: 14px; color: $adminGrayLight; }
  div.tags div.tag div.tag-done i.material-icons { display: flex; justify-content: center; align-items: center; width: 16px; height: 16px; margin: 0 10px 0 5px; border-radius: 50%; font-size: 14px; color: $adminGrayLight; cursor: pointer; }
  div.tags div.tag div.tag-done i.material-icons:hover { background: $adminGrayLight; color: #fff; }
  div.tags div.tag input { width: 200px; margin: 0; height: 30px; border-radius: 15px; font-size: 14px; }
    
  div.element-visible { margin: 10px; }

  p.error {
    color: $adminRedLight;
    font-size: 13px;
    margin: 5px 20px;
  }
  div.nav-flex {
    display: flex;
    justify-content: space-between;
  }

  div.thumbnail { height: 200px; margin: 20px; position: relative; }
  div.thumbnail img { max-height: 200px; position: absolute; top: 0; left: 0; border-radius: 3px; }

  button { margin: 0 20px 20px 20px; }

  @media (max-width: 400px) {
    div.admin-product-post ul li button { margin-top: 15px; }
  }
</style>
