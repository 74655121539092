<template>
  <div class="visonic intrusion-detecors">
    <button class="visonic-back" @click="$router.go(-1)">
      <i class="material-icons">west</i>
      <p>Powrót</p>
    </button>
    <div class="flex">
      <header class="products">
        <div class="container">
          <div class="img"></div>
          <h4>detektory wtargnięcia</h4>
        </div>
      </header>
      <section class="products">
        <header>
          <img src="../../../public/img/webp/visonic_intrusion_detectors.webp" alt="Detektory wtargnięcia">
        </header>
        <article
          v-for="(product, index) in products"
          :key="'product' + index"
        >
          <img :src="api + product.photo" :alt="product.name">
          <div class="content">
            <h5>{{ product.name }}</h5>
            <p v-html="$decodeHTML($sanitize(product.description))"></p>
            <footer>
              <button
                title="Pobierz instrukcje"
                @click="openFile({ type: 0, name: product.manual })"
                v-if="product.manual"
                :class="{ manual: product.manual }"
              ></button>
              <button
                title="Pobierz kartę katalogową"
                @click="openFile({ type: 1, name: product.catalog })"
                v-if="product.catalog"
                :class="{ catalog: product.catalog }"
              ></button>
            </footer>
          </div>
        </article>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IntrusionDetectors',
  data() {
    return {
      products: [],
    }
  },
  computed: {
    api() {
      return this.$store.state.api;
    },
  },
  async created(){
    await this.getProducts();
  },
  methods: {
    async getProducts() {
      let self = this;
      await this.axios.get(this.api + "product/client", { params: { categories: ['60f560195b36c1bab4d1eb51'] } } ).then((response) => {
        if (response.data.status == 1) {
          self.products = response.data.data;
        }
      });
    },
    openFile(file) {
      if(file.type === 0) {
        window.open('pliki/instrukcje/' + file.name, '_blank');
      } else if(file.type === 1) {
        window.open('pliki/katalogi/' + file.name, '_blank');
      };
    },
  },
}
</script>

<style lang="scss" scoped>
  div.img { background: url('../../../public/img/icon_visonic_1.png') no-repeat 50% 50%; }
</style>
