<template>
  <div id="app">
    <router-view/>
    <alert/>
  </div>
</template>

<script>
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import Alert from '@/components/Alert.vue'

export default {
  name: 'App',
  metaInfo: {
    meta: [
      { name: 'keywords', content: 'agencja ochrony, ochrona monitoring alarmy, ochrona fizyczna, ochrona osób i mienia, Oleśnica Wrocław NOVA, NOVA HOLDING' },
      { name: 'description', content: 'Licencjonowana agencja ochrony osób i mienia - NOVA M. Tytko Spółka Jawna - Wrocław, Oleśnica' },
      { name: 'theme-color', content: '#151b4d' },
    ]
  },
  components: {
    Alert,
  },
  data(){
    return{
      currentRequest: 0,
    }
  },
  computed:{
    api(){
      return this.$store.state.api;
    },
    content(){
      return this.$store.state.content;
    },
  },
  created() {
    let self = this;
    const refreshAuthLogic = failedRequest => this.axios.get(self.api + 'admin/token', { headers: { Authorization: localStorage.refreshToken } }).then(tokenRefreshResponse => {
      if(tokenRefreshResponse.data.status == 1){
        localStorage.accessToken = tokenRefreshResponse.data.accessToken;
        failedRequest.response.config.headers['Authorization'] = tokenRefreshResponse.data.accessToken;
        return Promise.resolve();
      }else{
        self.logout();
      }
    });
    createAuthRefreshInterceptor(this.axios, refreshAuthLogic, {
      statusCodes: [401],
      pauseInstanceWhileRefreshing: false,
    });
    this.axios.interceptors.request.use(request => {
        if(typeof request.headers['notoken'] === 'undefined' && request.url != this.api + 'admin/token'){
          request.headers['Authorization'] = self.getAccessToken();
        }
        return request;
    });
    this.getContent();
  },
  methods: {
    logout(){
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      this.$router.push('/admin');
    },
    getAccessToken(){
      return localStorage.getItem('accessToken');
    },
    getContent(){
      let self = this;
      this.axios
      .get(this.api + 'content', )
      .then(response => {
        if(response.data.status == 1){
          this.$store.commit('changeContent', response.data.data);
        }
      });
    },
  },
}
</script>

<style lang="scss">
  div#app { margin: 0; }
</style>
