<template>
  <div class="home">
    <section class="banner">
      <div class="left">
        <img src="../../public/img/logo_visonic_big.png" alt="Visonic">
      </div>
      <div class="banner" :class="{ img1: bannerImg == 1, img2: bannerImg == 2, img3: bannerImg == 3 }"></div>
    </section>
    <section class="links">
      <div class="bg">
        <ul>
          <li title="detektory wtargnięcia">
            <router-link :to="{ name: 'IntrusionDetectors' }">
              <div class="img icon_visonic_1"></div>
              <p>detektory wtargnięcia</p>
            </router-link>
          </li>
          <li title="czujniki bezpieczeństwa">
            <router-link :to="{ name: 'SafetySensors' }">
              <div class="img icon_visonic_2"></div>
              <p>czujniki bezpieczeństwa</p>
            </router-link>
          </li>
          <li title="Przyciski alarmowe">
            <router-link :to="{ name: 'AlarmButtons' }">
              <div class="img icon_visonic_3"></div>
              <p>przyciski<br> alarmowe</p>
            </router-link>
          </li>
          <li title="Syreny">
            <router-link :to="{ name: 'Sirens' }">
              <div class="img icon_visonic_4"></div>
              <p>syreny</p>
            </router-link>
          </li>
          <li title="Klawiatury">
            <router-link :to="{ name: 'Keyboards' }">
              <div class="img icon_visonic_5"></div>
              <p>klawiatury</p>
            </router-link>
          </li>
          <li title="Piloty">
            <router-link :to="{ name: 'Controllers' }">
              <div class="img icon_visonic_6"></div>
              <p>piloty</p>
            </router-link>
          </li>
          <li title="Aplikacja visonic alert">
            <router-link :to="{ name: 'VisonicApp' }">
              <div class="img icon_visonic_7"></div>
              <p>aplikacja<br> visonic alert</p>
            </router-link>
          </li>
        </ul>
      </div>
    </section>
    <section class="products">
     <article
      v-for="(product, index) in products"
      :key="'product' + index"
     >
       <img :src="api + product.photo" :alt="product.name">
       <div class="content">
         <h5>{{ product.name }}</h5>
         <p v-html="$decodeHTML(product.description)"></p>
         <footer>
          <button
            title="Pobierz instrukcje"
            @click="openFile({ type: 0, name: product.manual })"
            v-if="product.manual"
            :class="{ manual: product.manual }"
          ></button>
          <button
            title="Pobierz kartę katalogową"
            @click="openFile({ type: 1, name: product.catalog })"
            v-if="product.catalog"
            :class="{ catalog: product.catalog }"
          ></button>
         </footer>
       </div>
     </article>
    </section>
  </div>
</template>

<script>
export default {
  name: 'OfferVisonic',
  data(){
    return{
      bannerImg: 1,
      maxBannerImg: 3,
      bannerChangeInterval: 0,
      bannerChangeDuration: 7000,
      products: [],
    }
  },
  computed: {
    api() {
      return this.$store.state.api;
    },
  },
  async created(){
    let self = this;
    this.bannerChangeInterval = setInterval(function(){ 
      if(self.bannerImg == self.maxBannerImg){
        self.bannerImg = 1;
      }else{
        self.bannerImg++;
      }
    }, self.bannerChangeDuration)
    await this.getProducts();
  },
  destroyed(){
    clearInterval(this.bannerChangeInterval)
  },
  methods: {
    async getProducts() {
      let self = this;
      await this.axios.get(this.api + "product/client", { params: { categories: ['60f6c869875da16a54cb0715'] } } ).then((response) => {
        if (response.data.status == 1) {
          self.products = response.data.data;
        }
      });
    },
    openFile(file) {
      if(file.type === 0) {
        window.open('pliki/instrukcje/' + file.name, '_blank');
      } else if(file.type === 1) {
        window.open('pliki/katalogi/' + file.name, '_blank');
      };
    },
  },
}
</script>

<style lang="scss" scoped>
  section.banner { display: flex; margin: 0 0 30px 0; height: 611px; }
  section.banner div.left { display: flex; flex-direction: column; justify-content: center; height: 100%; margin: 0 auto; position: relative; }
  section.banner div.left > img { display: block; }
  section.banner div.banner { width: 1342px; height: 611px; border-top-left-radius: 500px; border-bottom-left-radius: 500px; transition: 2s; }
  section.banner div.banner.img1 { background: url('../../public/img/webp/banner1_visonic.webp') no-repeat 50% 50%; transition: 2s; }
  section.banner div.banner.img2 { background: url('../../public/img/webp/banner2_visonic.webp') no-repeat 50% 50%; transition: 2s; }
  section.banner div.banner.img3 { background: url('../../public/img/webp/banner3_visonic.webp') no-repeat 50% 50%; transition: 2s; }

  section.links ul li div.img.icon_visonic_1 { background: url('../../public/img/icon_visonic_1.png') no-repeat 50% 50%; }
  section.links ul li div.img.icon_visonic_2 { background: url('../../public/img/icon_visonic_2.png') no-repeat 50% 50%; }
  section.links ul li div.img.icon_visonic_3 { background: url('../../public/img/icon_visonic_3.png') no-repeat 50% 50%; }
  section.links ul li div.img.icon_visonic_4 { background: url('../../public/img/icon_visonic_4.png') no-repeat 50% 50%; }
  section.links ul li div.img.icon_visonic_5 { background: url('../../public/img/icon_visonic_5.png') no-repeat 50% 50%; }
  section.links ul li div.img.icon_visonic_6 { background: url('../../public/img/icon_visonic_6.png') no-repeat 50% 50%; }
  section.links ul li div.img.icon_visonic_7 { background: url('../../public/img/icon_visonic_7.png') no-repeat 50% 50%; }
  section.links ul li a:hover div.img.icon_visonic_1 { background: url('../../public/img/icon_visonic_1_white.png') no-repeat 50% 50%; }
  section.links ul li a:hover div.img.icon_visonic_2 { background: url('../../public/img/icon_visonic_2_white.png') no-repeat 50% 50%; }
  section.links ul li a:hover div.img.icon_visonic_3 { background: url('../../public/img/icon_visonic_3_white.png') no-repeat 50% 50%; }
  section.links ul li a:hover div.img.icon_visonic_4 { background: url('../../public/img/icon_visonic_4_white.png') no-repeat 50% 50%; }
  section.links ul li a:hover div.img.icon_visonic_5 { background: url('../../public/img/icon_visonic_5_white.png') no-repeat 50% 50%; }
  section.links ul li a:hover div.img.icon_visonic_6 { background: url('../../public/img/icon_visonic_6_white.png') no-repeat 50% 50%; }
  section.links ul li a:hover div.img.icon_visonic_7 { background: url('../../public/img/icon_visonic_7_white.png') no-repeat 50% 50%; }
  
  @media (max-width: 1700px) {
    section.banner div.left > img { display: none; }
  }

  @media (max-width: 1500px) {
    section.links { min-height: 200px; height: initial; }
    section.links div.bg { width: 100%; border-radius: 0; }
    section.links ul { flex-wrap: wrap; }
    section.links ul li { margin: 25px; }
  }

  @media (max-width: 1342px) {
    section.banner div.banner { width: 100%; border-radius: 0; transition: 0s; }
  }

  @media (max-width: 1300px) {
    section.about img { display: none; }
  }

  @media (max-width: 1000px) {
    section.banner { height: 400px; margin: 0 0 1px 0; }
    section.banner div.banner { height: 100%; transition: 0s; }
    section.banner div.banner.img1 { background: url('../../public/img/webp/banner1_visonic_medium.webp') no-repeat 50% 50%; }
    section.banner div.banner.img2 { background: url('../../public/img/webp/banner2_visonic_medium.webp') no-repeat 50% 50%; }
    section.banner div.banner.img3 { background: url('../../public/img/webp/banner3_visonic_medium.webp') no-repeat 50% 50%; }
  }

   @media (max-width: 600px) {
    section.banner { height: 200px; }
  }

  @media (max-width: 500px) {
    section.banner div.banner.img1 { background: url('../../public/img/webp/banner1_visonic_small.webp') no-repeat 50% 50%; }
    section.banner div.banner.img2 { background: url('../../public/img/webp/banner2_visonic_small.webp') no-repeat 50% 50%; }
    section.banner div.banner.img3 { background: url('../../public/img/webp/banner3_visonic_small.webp') no-repeat 50% 50%; }
  }
</style>
