<template>
  <nav v-if="navMobile">
    <header>
      <router-link to="/" @click.native="close()"></router-link>
      <div class="menu" @click="close()">
        <i class="material-icons">close</i>
      </div>
    </header>
    <ul>
      <li><router-link to="/" :class="{ active: activeRoute == 'Home' }" @click.native="close()">Strona główna</router-link></li>
      <li><router-link to="/oferta" :class="{ active: activeRoute == 'Offer' }" @click.native="close()">Oferta</router-link></li>
      <li><router-link to="/oferta-visonic" :class="{ active: activeRoute == 'OfferVisonic' || activeRoute == 'AlarmButtons' || activeRoute == 'Controllers' || activeRoute == 'IntrusionDetectors' 
        || activeRoute == 'Keyboards' || activeRoute == 'SafetySensors' || activeRoute == 'Sirens' || activeRoute == 'VisonicApp' }" @click.native="close()">oferta visonic</router-link></li>
      <li><router-link to="/kontakt" :class="{ active: activeRoute == 'Contact' }" @click.native="close()">Kontakt</router-link></li>
      <li><router-link to="/rodo" :class="{ active: activeRoute == 'Rodo' }" @click.native="close()">rodo</router-link></li>
      <li style="display: none"><router-link to="/" @click.native="close()">Zacznij</router-link></li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'TheNavMobile',
  data(){
    return{
      activeRoute: this.$route.name,
    }
  },
  computed:{
    navMobile(){
      return this.$store.state.navMobile;
    },
  },
  watch:{
    $route(to, from){
      this.activeRoute = this.$route.name;
      this.close();
    }
  },
  methods:{
    close(){
      this.$store.commit('toggleNavMobile', 0);
      document.body.style = "overflow-y: overlay";
    },
  },
}
</script>
<style scoped lang="scss">
  nav { display: block; width: 100vw; height: 100vh; position: fixed; top: 0; z-index: 4; overflow-y: auto; background: #fff; }
  nav ul { margin: 0; padding: 0; list-style: none; }
  nav ul li a { display: block; height: 60px; line-height: 60px; text-align: center; color: $secondary; text-decoration: none; text-transform: uppercase; font-size: 13px; letter-spacing: 1px; font-weight: 500; }
  nav ul li a:hover { color: $primary; }
  nav ul li a.active { color: $primary; }

  header { display: flex; justify-content: space-between; height: 70px; border-bottom: 1px solid $primary; }
  header a { display: block; width: 70px; height: 70px; background: url('../assets/logo_small.png') no-repeat 50% 50%; }
  div.menu { display: flex; justify-content: center; align-items: center; width: 70px; height: 70px; }
  div.menu:hover { background: rgba(255, 255, 255, 0.1); transition: .5s; cursor: pointer; }
  div.menu i.material-icons { color: $primary; font-size: 32px; }
  div.menu:hover i.material-icons { color: $secondary; }

  @media (min-width: 1000px) {
    nav { display: none; }
  }
</style>
