<template>
  <nav class="admin-the-sub-nav">
     <ul>
       <li v-for="(element, index) in data" :key="index">
          <router-link :to="{ name: element.component }" :class="{ active: activeRoute == element.component }">{{ element.title }}</router-link>
      </li>
     </ul>
  </nav>
</template>

<script>
export default {
  name: 'AdminTheSubNav',
  data(){
    return{
      activeRoute: this.$route.name,
    }
  },
  props: {
    data: Array,
  },
  watch:{
    $route(to, from){
      this.activeRoute = this.$route.name;
    }
  }
}
</script>
<style lang="scss">
  nav.admin-the-sub-nav { margin: 0 20px 20px 20px; }
  nav.admin-the-sub-nav ul { display: flex; flex-wrap: wrap; margin: 0; padding: 0; list-style: none; }
  nav.admin-the-sub-nav ul li a, nav.admin-the-sub-nav ul li button { display: inline-block; margin: 20px 15px 0 0; padding: 7.5px 15px; text-decoration: none; font-weight: 400; text-transform: uppercase; font-size: 14px; color: $adminGrayDark; border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px; background: #EEEEEE; cursor: pointer; }
  nav.admin-the-sub-nav ul li:last-child a, nav.admin-the-sub-nav ul li:last-child button { margin-right: 0; }
  nav.admin-the-sub-nav ul li a:hover, nav.admin-the-sub-nav ul li a.active, nav.admin-the-sub-nav ul li button:hover, nav.admin-the-sub-nav ul li button.active { background: $adminTealLight; color: rgba(255, 255, 255, 0.9); font-weight: 400; }
</style>
