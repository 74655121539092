<template>
  <div class="contact">
    <div class="contact-data-wrapper">
      <div class="contact-data">
        <div class="contact-segment">
          <div>
            <div class="data" style="align-items: flex-start;">
              <div class="icon" style="margin-top: 4px;"><i class="material-icons">home</i></div>
              <div>
                <h5>NOVA WD Sp. z o.o. Sp. Komandytowa</h5>
                <h6>ul. Świeradowska 51-57 50-559 Wrocław</h6>
                <div style="display: flex; justify-content: space-between;">
                  <h6>NIP: 8992737149</h6>
                  <h6>REGON: 021930501</h6>
                </div>
                <h6>KRS: 0000771021</h6>
                <h6>BDO: 000587572</h6>
              </div>
            </div>
          </div>
          <div>
            <div class="data">
              <div class="icon"><i class="material-icons">home</i></div>
              <div>
                <h5>BIURO</h5>
                <h6>ul. Lwowska 31/402  56-400 Oleśnica</h6>
              </div>
            </div>
            <div class="flex">
              <div class="data">
                <div class="icon"><i class="material-icons">phone_android</i></div>
                <a href="tel:730007170">+48 730 007 170</a>
              </div>
            </div>
            <div class="data">
              <div class="icon"><i class="material-icons">email</i></div>
              <a href="mailto:biuro@novaholding.pl">biuro@novaholding.pl</a>
            </div>
          </div>
          <div>
            <div class="data">
              <div class="icon"><i class="material-icons">settings</i></div>
              <div>
                <h5>MONITORING I SERWIS TECHNICZNY</h5>
              </div>
            </div>
            <div class="flex">
              <div class="data">
                <div class="icon"><i class="material-icons">phone_android</i></div>
                    <a href="tel:530302032">+48 530 302 032</a>
              </div>
              <div class="data">
                <div class="icon"><i class="material-icons">phone</i></div>
                <a href="tel:713969497">71 396 94 97</a>
              </div>
            </div>
            <div class="data">
              <div class="icon"><i class="material-icons">email</i></div>
              <a href="mailto:serwis@novaholding.pl">serwis@novaholding.pl</a>
            </div>
          </div>
          <div>
            <div class="data">
              <div class="icon"><i class="material-icons">home</i></div>
              <div>
                <h5>ODDZIAŁ WAŁBRZYCH</h5>
                <h6>ul. Piotra Wysockiego 10/10 58-300 Wałbrzych</h6>
              </div>
            </div>
            <div class="flex">
              <div class="data">
                <div class="icon"><i class="material-icons">phone_android</i></div>
                <a href="tel:730003132">+48 730 003 132</a>
              </div>
            </div>
            <div class="data">
              <div class="icon"><i class="material-icons">email</i></div>
              <a href="mailto:walbrzych@novaholding.pl">walbrzych@novaholding.pl</a>
            </div>
          </div>
        </div>
      </div>
    </div>
   <div class="">
      <div class="inputs">
        <input
          type="text"
          placeholder="Imię i nazwisko"
          v-model="nameAndSurname"
          :class="{ 'error': errors.nameAndSurname }"
        >
        <div class="error-wrapper">
          <p class="error">{{ errors.nameAndSurname }}</p>
        </div>
        <input
          type="text"
          placeholder="Adres e-mail"
          v-model="email"
          :class="{ 'error': errors.email }"
        >
        <div class="error-wrapper">
          <p class="error">{{ errors.email }}</p>
        </div>
        <input
          type="text"
          placeholder="Temat"
          v-model="subject"
          :class="{ 'error': errors.subject }"
        >
        <div class="error-wrapper">
          <p class="error">{{ errors.subject }}</p>
        </div>
      </div>
    </div>
    <textarea
      placeholder="Treść wiadomości"
      v-model="content"
      :class="{ 'error': errors.content }"
    ></textarea>
    <div class="error-wrapper textarea">
      <p class="error">{{ errors.content }}</p>
    </div>
    <div class="button">
      <button @click="sendForm">Wyślij</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact',
  data(){
    return{
      nameAndSurname: '',
      email: '',
      subject: '',
      content: '',
      errors: {
        nameAndSurname: '',
        email: '',
        subject: '',
        content: '',
      },
    }
  },
  computed:{
    api(){
      return this.$store.state.api;
    },
  },
  methods: {
    async sendForm() {
      Object.keys(this.errors).forEach(error => { this.errors[error] = ''; });
      const payload = {
        nameAndSurname: this.nameAndSurname,
        email: this.email,
        subject: this.subject,
        content: this.content
      };
      const self = this;
      await this.axios
      .post(this.api + 'mailing/', { message: payload })
      .then(response => {
        if(response.data.status === 1) {
          this.$store.commit('toggleAlert', {
            visibility: 1,
            type: 1,
            msg: response.data.msg
          });
          this.nameAndSurname = '';
          this.email = '';
          this.subject = '';
          this.content = '';
        } else if(response.data.status === 0) {
          if(response.data.msgs) {
            response.data.fields.forEach(field => {
            this.errors[field] = response.data.msgs[response.data.fields.indexOf(field)];
          });
          } else {
            this.$store.commit('toggleAlert', {
              visibility: 1,
              type: 0,
              msg: response.data.msg
            });
          };
        };
      })
      .catch((error) => {
        this.$store.commit('toggleAlert', {
          visibility: 1,
          type: 0,
          msg: error.response.data
        });
      });
    },
  },
}
</script>

<style lang="scss" scoped>
  div.contact { padding: 50px 0; }
  div.flex { display: flex; }
  div.flex-end { display: flex; justify-content: flex-end; }

  div.error-wrapper {
    height: 20px;
  }
  div.error-wrapper.textarea {
    width: 1050px;
    margin: 0 auto;
  }
  div.error-wrapper p {
    padding-left: 16px;
    line-height: 20px;
    font-size: 13px;
    color: $red;
    background: initial;
  }
  input { display: block; width: 1050px; height: 70px; margin: 0 auto; padding: 0 15px; border: 2px solid $primary; color: $primary; font-size: 16px; background: none; }
  input::placeholder { color: rgba(0, 0, 0, 0.5); }
  textarea { display: block; width: 1050px; height: 400px; margin: 0 auto; padding: 15px;  border: 2px solid $primary; color: $primary; font-size: 16px; background: none; }
  textarea::placeholder { color: rgba(0, 0, 0, 0.5); }
  
  textarea.error,
  input.error {
    border-color: $red;
  }
  textarea.error:focus,
  input.error:focus {
    padding-left: 14px;
    outline: none;
    border: 3px solid $red;
    border-radius: 5px;
  }

  div.contact-data-wrapper { display: flex; flex-wrap: wrap; justify-content: flex-end; width: 100%; }
  div.contact-data { display: flex; flex-direction: column; justify-content: space-evenly; width: 95%; min-height: 250px; margin-bottom: 50px; padding-left: 100px; background: $gray; border-top-left-radius: 200px; border-bottom-left-radius: 200px; }
  div.contact-data div.contact-segment { display: flex; flex-wrap: wrap; justify-content: center; gap: 50px; }
  div.contact-data div.contact-segment > div { display: flex; flex-direction: column; gap: 10px; }
  div.contact-data div.data { display: flex; align-items: center; }
  div.contact-data div.data div.icon { display: flex; justify-content: center; align-items: center; width: 30px; height: 30px; border-radius: 50%; background: $primary; }
  div.contact-data div.data div.icon i.material-icons { color: #fff; font-size: 18px; }
  div.contact-data > div.data:first-child h5 { font-size: 20px; }
  div.contact-data div.data h5 { margin: 0 0 0 20px; padding: 0; color: $primary; font-weight: 700; font-size: 15px; }
  div.contact-data div.data h6 { margin: 5px 0 0 20px; padding: 0; color: $primary; font-weight: 500; font-size: 14px; }
  div.contact-data div.data a { margin: 0 0 0 20px; padding: 0; color: $primary; font-weight: 700; font-size: 15px; text-decoration: none; }
  div.contact-data div.data a:hover { text-decoration: underline; }
  div.contact-data div.flex div.data:first-child { margin-right: 50px; }

  div.button { width: 1050px; margin: 20px auto; }
  div.button button { padding: 10px 33px; background: $primary; outline: 0; border: 2px solid $primary; color: #fff; border-radius: 3px; font-size: 13px; cursor: pointer; }
  div.button button:hover { background: #fff; color: $primary; border: 2px solid $primary; }

  @media (max-width: 1700px) {
    div.contact-data div.contact-segment { display: grid; grid-template-columns: 1fr 1fr; padding: 20px; }
  }

   @media (max-width: 1090px) {
    div.contact { padding: 0; }
    div.flex-end { flex-wrap: wrap; }
    div.flex-end div.inputs { order: 2; }

    div.error-wrapper.textarea { width: calc(100vw - 40px); }
    textarea { width: calc(100vw - 40px); }
    input { width: calc(100vw - 40px); }

    div.contact-data { order: 1; width: calc(100vw); margin: 0 auto 20px auto; padding: 20px; border-radius: 0; }
    div.contact-data div.contact-segment { display: flex; flex-direction: column; justify-content: flex-start; }

    div.button { width: calc(100vw - 40px); }
  }

  @media (max-width: 500px) {
    div.contact-data { height: initial; padding: 15px 20px; }
    div.contact-data div.data { margin: 15px 0; }
    div.contact-data div.data div.icon { flex-shrink: 0; }
  }
</style>
