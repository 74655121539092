<template>
  <footer>
    <div class="flex">
      <div>
        <ul>
          <li title="Strona główna"><router-link to="/">strona główna</router-link></li>
          <li title="Oferta"><router-link to="/oferta">oferta</router-link></li>
          <li title="Oferta Visonic"><router-link to="/oferta-visonic">oferta visonic</router-link></li>
          <li title="Kontakt"><router-link to="/kontakt">kontakt</router-link></li>
          <li title="RODO"><router-link to="/rodo">rodo</router-link></li>
        </ul>
        <h6>Wszelkie prawa zastrzeżone - NOVA Holding {{ year }}</h6>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'TheFooter',
  data(){
    return{
      year: 2023,
    }
  },
  mounted(){
    this.year = new Date().getFullYear();
  }
}
</script>
<style scoped lang="scss">
  footer { display: flex; justify-content: flex-end; width: 100%; height: 200px; }
  footer div.flex { display: flex; justify-content: center; align-items: center; width: 95%; height: 100%; border-top-left-radius: 100px; border-bottom-left-radius: 100px; background: $gray; }
  footer div.flex div { display: flex; flex-direction: column; align-items: center; }

  div.contact p { margin: 5px 0; padding: 0; font-size: 14px; color: $primary; font-weight: 700; }
  div.contact p a { text-decoration: none; color: $primary; }
  div.contact p a:hover { text-decoration: underline; }

  h6 { margin: 40px 0 0 0; padding: 0; font-weight: 500; color: #fff; font-size: 12px; }
  ul { display: flex; margin: 0; padding: 0; list-style: none; }
  ul li a { margin: 0 50px 0 0; padding: 0; color: $primary; text-decoration: none; text-transform: uppercase; font-size: 14px; font-weight: 600; }
  ul li a:hover { text-decoration: underline; }

  @media (max-width: 1000px) {
    footer { height: 35px; }
    footer div.flex { width: 100%; border-radius: 0; }
    div.contact { display: none; }
    h6 { margin: 0; }
    ul { display: none; }
  }
</style>
