import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    api: process.env.VUE_APP_API,
    navMobile: 0,
    adminNavMobile: 0,
    alert:{
      visibility: 0,
      type: 0,
      msg: '',
    },
    content: 0,
    editorToolbars: {
      article: [	
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" }
        ],
        ["blockquote",],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link",],
        ["clean"] // remove formatting button
        ],
    },
    particles: {
      fpsLimit: 60,
      particles: {
        color: {
            value: '#fff'
        },
        links: {
            color: '#fff',
            distance: 150,
            enable: true,
            opacity: 0.4,
            width: 1
        },
        move: {
            enable: true,
            outMode: 'bounce',
            speed: 0.5,
        },
        opacity: {
            value: 0.5
        },
        shape: {
            type: 'circle'
        },
        size: {
            random: true,
            value: 4
        },
        retina_detect: false
      },
    },
  },
  mutations: {
    toggleAdminNavMobile(state, payload){
      state.adminNavMobile = payload;
    },
    toggleNavMobile(state, payload){
      state.navMobile = payload;
    },
    changeContent(state, payload){
      state.content = payload;
    },
    toggleAlert(state, payload){
      state.alert.visibility = payload.visibility;
      state.alert.type = payload.type;
      state.alert.msg = payload.msg;
    },
  },
  actions: {
  },
  modules: {
  }
})
