import Vue from 'vue'
import VueRouter from 'vue-router'

// Admin
import AdminLogin from '../views/admin/Login.vue'
import AdminWrapper from '../views/admin/Wrapper.vue'
import AdminContent from '../views/admin/Content.vue'
import AdminSettings from '../views/admin/Settings.vue'
import AdminProducts from '../views/admin/products/ProductsWrapper.vue';
import Products from '../views/admin/products/Products.vue';
import AddProduct from '../components/admin/products/AddProduct.vue';
import EditProduct from '../components/admin/products/EditProduct.vue';

//Main

import Wrapper from '../views/Wrapper.vue'
import Home from '../views/Home.vue'
import Offer from '../views/Offer.vue'
import OfferVisonic from '../views/OfferVisonic.vue'
import Contact from '../views/Contact.vue'
import Rodo from '../views/Rodo.vue'

// Visonic
import IntrusionDetectors from '../views/visonic/IntrusionDetectors.vue'
import SafetySensors from '../views/visonic/SafetySensors.vue'
import AlarmButtons from '../views/visonic/AlarmButtons.vue'
import Sirens from '../views/visonic/Sirens.vue'
import Keyboards from '../views/visonic/Keyboards.vue'
import Controllers from '../views/visonic/Controllers.vue'
import VisonicApp from '../views/visonic/VisonicApp.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/admin',
    name: 'AdminLogin',
    component: AdminLogin
  },
  {
    path: '/admin/wrapper',
    name: 'AdminWrapper',
    component: AdminWrapper,
    redirect: {
      name: 'AdminContent'
    },
    children: [
      {
        path: '/admin/content',
        name: 'AdminContent',
        component: AdminContent,
      },
      {
        path: '/admin/settings',
        name: 'AdminSettings',
        component: AdminSettings,
      },
      {
        path: '/admin/products',
        component: AdminProducts,
        children: [
          {
            path: '/',
            name: 'Products',
            component: Products
          },
          {
            path: 'new',
            name: 'AddProduct',
            component: AddProduct
          },
          {
            path: ':productId',
            name: 'EditProduct',
            component: EditProduct
          }
        ]
      },
    ]
  },
  {
    path: '/',
    component: Wrapper,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home,
      },
      {
        path: '/oferta',
        name: 'Offer',
        component: Offer,
      },
      {
        path: '/oferta-visonic',
        name: 'OfferVisonic',
        component: OfferVisonic,
      },
      {
        path: '/kontakt',
        name: 'Contact',
        component: Contact,
      },
      {
        path: '/rodo',
        name: 'Rodo',
        component: Rodo,
      },
      {
        path: '/oferta-visonic/detektory-wtargniecia',
        name: 'IntrusionDetectors',
        component: IntrusionDetectors,
      },
      {
        path: '/oferta-visonic/czujniki-bezpieczenstwa',
        name: 'SafetySensors',
        component: SafetySensors,
      },
      {
        path: '/oferta-visonic/przyciski-alarmowe',
        name: 'AlarmButtons',
        component: AlarmButtons,
      },
      {
        path: '/oferta-visonic/syreny',
        name: 'Sirens',
        component: Sirens,
      },
      {
        path: '/oferta-visonic/klawiatury',
        name: 'Keyboards',
        component: Keyboards,
      },
      {
        path: '/oferta-visonic/piloty',
        name: 'Controllers',
        component: Controllers,
      },
      {
        path: '/oferta-visonic/aplikacja-visonic-alert',
        name: 'VisonicApp',
        component: VisonicApp,
      },
    ]
  },
]

const router = new VueRouter({
  routes,
  mode: process.env.VUE_APP_ROUTER_MODE,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }else if (savedPosition) {
      return savedPosition
    }else{
      return { x: 0, y: 0 }
    }
  }
})

export default router
