<template>
  <div class="home" v-if="content">
    <section class="banner">
      <div class="left">
        <img src="../../public/img/logo_big.png" alt="Novaholding">
        <div class="distributor">
          <h6>Dystrybutor firmy</h6>
          <router-link :to="{ name: 'OfferVisonic' }" title="Oferta Visonic">
           <img src="../../public/img/visonic.png" alt="Visonic">
          </router-link>
        </div>
      </div>
      <div class="banner" :class="{ img1: bannerImg == 1, img2: bannerImg == 2, img3: bannerImg == 3 }"></div>
    </section>
    <section class="links">
      <div class="bg">
        <ul>
          <li title="Ochrona twojego domu">
            <router-link :to="{ name: 'Offer', hash: '#ochrona_twojego_domu' }">
              <div class="img icon_home_1"></div>
              <p>ochrona twojego domu</p>
            </router-link>
          </li>
          <li title="Ochrona techniczna osób i mienia">
            <router-link :to="{ name: 'Offer', hash: '#ochrona_techniczna_osob_i_mienia' }">
              <div class="img icon_home_2"></div>
              <p>ochrona techniczna osób i mienia</p>
            </router-link>
          </li>
          <li title="Energooszczędne technologie">
            <router-link :to="{ name: 'Offer', hash: '#energooszczedne_technologie' }">
              <div class="img icon_home_3"></div>
              <p>energooszczędne technologie</p>
            </router-link>
          </li>
          <li title="Audyt bezpieczeństwa">
            <router-link :to="{ name: 'Offer', hash: '#audyt_bezpieczenstwa' }">
              <div class="img icon_home_4"></div>
              <p>audyt bezpieczeństwa</p>
            </router-link>
          </li>
        </ul>
      </div>
    </section>
    <section class="about">
      <div class="content">
        <p v-html="$decodeHTML($sanitize($getContent(page, 'Artykuł góra', 'Artykuł góra', 'treść')))"></p>
      </div>
      <img src="../../public/img/camera.png" alt="Kamerka">
    </section>
    <section class="benefits">
      <div class="bg">
        <img src="../../public/img/home_house.png" alt="Dom">
      </div>
      <div class="container">
        <h3 v-html="$decodeHTML($sanitize($getContent(page, 'Artykuł dół', 'Artykuł dół', 'tytuł')))"></h3>
        <p v-html="$decodeHTML($sanitize($getContent(page, 'Artykuł dół', 'Artykuł dół', 'treść')))"></p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data(){
    return{
      page: 'Strona Główna',
      bannerImg: 1,
      maxBannerImg: 3,
      bannerChangeInterval: 0,
      bannerChangeDuration: 5000
    }
  },
  computed: {
    content() {
      return this.$store.state.content;
    }
  },
  created(){
    let self = this;
    this.bannerChangeInterval = setInterval(function(){ 
      if(self.bannerImg == self.maxBannerImg){
        self.bannerImg = 1;
      }else{
        self.bannerImg++;
      }
    }, self.bannerChangeDuration)
  },
  destroyed(){
    clearInterval(this.bannerChangeInterval)
  }
}
</script>

<style lang="scss" scoped>
  section.banner { display: flex; margin: 0 0 30px 0; height: 611px; }
  section.banner div.left { display: flex; flex-direction: column; justify-content: center; height: 100%; margin: 0 auto; position: relative; }
  section.banner div.left > img { display: block; }
  section.banner div.distributor { display: flex; align-items: flex-end; position: absolute; bottom: 0; right: -250px; }
  section.banner div.distributor h6 { margin: 0 10px 0 0; padding: 0; font-size: 16px; color: $primary; font-weight: 600; }
  section.banner div.distributor img { display: block; margin: 0 0 -7px 10px; }
  section.banner div.banner { width: 1342px; height: 611px; border-top-left-radius: 500px; border-bottom-left-radius: 500px; transition: 2s; }
  section.banner div.banner.img1 { background: url('../../public/img/webp/banner1.webp') no-repeat 50% 50%; transition: 2s; }
  section.banner div.banner.img2 { background: url('../../public/img/webp/banner2.webp') no-repeat 50% 50%; transition: 2s; }
  section.banner div.banner.img3 { background: url('../../public/img/webp/banner3.webp') no-repeat 50% 50%; transition: 2s; }

  section.links ul li div.img.icon_home_1 { background: url('../../public/img/icon_home_1.png') no-repeat 50% 50%; }
  section.links ul li div.img.icon_home_2 { background: url('../../public/img/icon_home_2.png') no-repeat 50% 50%; }
  section.links ul li div.img.icon_home_3 { background: url('../../public/img/icon_home_3.png') no-repeat 50% 50%; }
  section.links ul li div.img.icon_home_4 { background: url('../../public/img/icon_home_4.png') no-repeat 50% 50%; }
  section.links ul li a:hover div.img.icon_home_1 { background: url('../../public/img/icon_home_1_white.png') no-repeat 50% 50%; }
  section.links ul li a:hover div.img.icon_home_2 { background: url('../../public/img/icon_home_2_white.png') no-repeat 50% 50%; }
  section.links ul li a:hover div.img.icon_home_3 { background: url('../../public/img/icon_home_3_white.png') no-repeat 50% 50%; }
  section.links ul li a:hover div.img.icon_home_4 { background: url('../../public/img/icon_home_4_white.png') no-repeat 50% 50%; }

  section.about { display: flex; justify-content: center; margin: 40px 0; }
  section.about div.content { width: 800px; }
  section.about div.content p { margin: 20px 0; color: $primary; font-size: 15px; font-weight: 500; line-height: 18px; text-align: justify; }

  section.benefits { display: flex; align-items: center; width: 100%; margin: 20px 0; }
  section.benefits div.bg { display: flex; align-items: center; justify-content: flex-end; width: 50%; height: 450px; margin-right: 20px; background: $secondary; border-top-right-radius: 250px; border-bottom-right-radius: 250px; }
  section.benefits div.bg img { display: block; margin: 0 20px 20px 0; }
  section.benefits div.container { margin-left: 20px; }
  section.benefits h3 { margin: 0 0 35px 0; padding: 0; font-size: 40px; text-transform: uppercase; font-weight: 700 }
  section.benefits ul { margin: 0; padding: 0; list-style: none; }
  section.benefits ul li { display: flex; align-items: center; margin: 25px 0; }
  section.benefits ul li div.bullet { width: 42px; height: 42px; background: url('../../public/img/icon_shield.png') no-repeat 50% 50%; }
  section.benefits ul li p { margin: 0 0 0 15px; padding: 0; font-size: 14px; text-transform: uppercase; font-weight: 700; }

  @media (max-width: 1800px) {
    section.banner div.distributor { right: 0; }
  }

  @media (max-width: 1700px) {
    section.banner div.left > img { display: none; }
    section.banner div.distributor { display: none; }
  }

  @media (max-width: 1342px) {
    section.banner div.banner { width: 100%; border-radius: 0; transition: 0s; }
  }

  @media (max-width: 1300px) {
    section.about img { display: none; }
  }

  @media (max-width: 1000px) {
    section.banner { height: 400px; margin: 0 0 1px 0; }
    section.banner div.banner { height: 100%; transition: 0s; }
    section.banner div.banner.img1 { background: url('../../public/img/webp/banner1_medium.webp') no-repeat 50% 50%; }
    section.banner div.banner.img2 { background: url('../../public/img/webp/banner2_medium.webp') no-repeat 50% 50%; }
    section.banner div.banner.img3 { background: url('../../public/img/webp/banner3_medium.webp') no-repeat 50% 50%; }

    section.links { min-height: 200px; height: initial; }
    section.links div.bg { width: 100%; border-radius: 0; }
    section.links ul { flex-wrap: wrap; }
    section.links ul li { margin: 25px; }
  }

  @media (max-width: 840px) {
    section.about div.content { width: calc(100% - 40px); }
  }

  @media (max-width: 800px) {
    section.benefits { margin: 20px 0 0 0;  }
    section.benefits div.bg { display: none; }
    section.benefits div.container { margin-left: 0; }
    section.benefits h3 { margin: 0 20px 35px 20px; font-size: 32px; }
    section.benefits ul { margin: 0 20px; }
  }

  @media (max-width: 600px) {
    section.banner { height: 200px; }
  }

  @media (max-width: 500px) {
    section.banner div.banner.img1 { background: url('../../public/img/webp/banner1_small.webp') no-repeat 50% 50%; }
    section.banner div.banner.img2 { background: url('../../public/img/webp/banner2_small.webp') no-repeat 50% 50%; }
    section.banner div.banner.img3 { background: url('../../public/img/webp/banner3_small.webp') no-repeat 50% 50%; }
  }
  section.benefits::v-deep ul { margin: 0; padding: 0; }
  section.benefits::v-deep ul li { display: flex; align-items: center; margin: 25px 0; list-style: none; font-size: 14px; font-weight: 700; }
  section.benefits::v-deep ul li::before { content: ''; display: inline-block; margin-right: 15px; width: 42px; height: 42px; background-image: url('../../public/img/icon_shield.png'); }
</style>
