<template>
  <div class="admin-settings">
    <div class="admin-box password">
      <header class="admin-box-header">
        <h6>Zmiana hasła</h6>
      </header>
      <input class="admin-default" type="password" placeholder="Hasło" v-model="changePasswordVars.password1" @keyup.enter="changePassword()">
      <input class="admin-default" type="password" placeholder="Powtórz Hasło" v-model="changePasswordVars.password2" @keyup.enter="changePassword()">
      <div class="flex-end">
        <button class="admin-default teal" @click="changePassword()">Zmień</button>
      </div>
    </div>
    <div class="admin-box" v-if="settingData.formEmail">
      <header class="admin-box-header">
        <h6>Email na który wysyłane są formularze</h6>
      </header>
      <p>Aktualny email: {{ settingData.formEmail.value }}</p>
      <input class="admin-default" type="text" placeholder="Email" v-model="newFormEmail">
      <div class="flex-end">
        <button class="admin-default teal" @click="changeFormEmail">Zmień</button>
      </div>
    </div>
  </div>
</template>

<script>
import TheSubNav from '../../components/admin/TheSubNav.vue'

export default {
  name: 'AdminContent',
  components: {
    TheSubNav,
  },
  data(){
    return{
      settingData: {},
      changePasswordVars: {
        password1: '',
        password2: '',
      },
      newFormEmail: '',
    }
  },
  computed:{
    api(){
      return this.$store.state.api;
    },
  },
  created() {
    this.getSettingData();
  },
  methods: {
      changePassword(){
        if(this.changePasswordVars.password1 == '' || this.changePasswordVars.password2 == ''){
          this.$store.commit('toggleAlert', { visibility: 1, type: 0, msg: 'Hasła nie mogą być puste' });
        }else if(this.changePasswordVars.password1 != this.changePasswordVars.password2){
          this.$store.commit('toggleAlert', { visibility: 1, type: 0, msg: 'Hasła muszą być takie same' });
        }else{
          let self = this;
          this.axios
          .put(this.api + 'admin/password',  { password: this.changePasswordVars.password1, repeatedPassword: this.changePasswordVars.password2 })
          .then(response => {
            if(response.data.status == 1){
              this.changePasswordVars.password1 = '';
              this.changePasswordVars.password2 = '';
            }
            self.$store.commit('toggleAlert', {
              visibility: 1,
              type: response.data.status,
              msg: response.data.msg
            });
          });
        }
    },
    getSettingData() {
      const self = this;
      this.axios
      .get(this.api + 'admin/setting')
      .then(response => {
        if(response.data.status === 1) {
          self.settingData = response.data.data;
          response.data.data.forEach(element => {
            self.settingData[element.name] = element;
          });
        };
      });
    },
    changeFormEmail() {
      const self = this;
      this.axios
      .put(this.api + 'admin/setting', { setting: { _id: this.settingData.formEmail._id, value: this.newFormEmail, name: this.settingData.formEmail.name } })
      .then(response => {
        self.$store.commit('toggleAlert', {
          visibility: 1,
          type: response.data.status,
          msg: response.data.msg
        });
        self.getSettingData();
      });
    },
  }
}
</script>

<style scoped lang="scss">
  div.admin-settings { display: flex; padding: 10px; }
  div.admin-box { margin: 10px }
  div.admin-box p { font-size: 13px; margin: 20px; }
  input.admin-default { margin: 20px;  width: calc(100% - 40px); font-family: 'Roboto', sans-serif; }
  div.flex-end { display: flex; justify-content: flex-start; margin: 20px; }
  div.password { margin: 10px; width: 400px; }

  @media (max-width: 400px) {
    div.wrapper { width: 100vw; }
  }
</style>
