<template>
  <div class="rodo">
   <section class="banner">
      <div class="left">
        <img src="../../public/img/logo_big.png" alt="Novaholding">
        <div class="distributor">
          <h6>Dystrybutor firmy</h6>
          <router-link :to="{ name: 'OfferVisonic' }" title="Oferta Visonic">
           <img src="../../public/img/visonic.png" alt="Visonic">
          </router-link>
        </div>
      </div>
      <div class="banner"></div>
    </section>
    <section class="rodo-links">
      <a href="./rodo/obowiązek_informacyjny_dla_Klientów_Kontrahentów.pdf" target="_blank" class="link">
        <div class="icon"></div>
        <h6>Obowiązek informacyjny wynikający z RODO dla Klientów, Kontrahentów</h6>
      </a>
      <a href="./rodo/Obowiązek_informacyjny_wynikający_z_RODO_dla_osób_postronnych.pdf" target="_blank"  class="link">
        <div class="icon"></div>
        <h6>Obowiązek informacyjny wynikający z RODO dla osób postronnych</h6>
      </a>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Rodo',
  data(){
    return{

    }
  },
}
</script>

<style lang="scss" scoped>
  section.banner { display: flex; margin: 0 0 30px 0; height: 611px; }
  section.banner div.left { display: flex; flex-direction: column; justify-content: center; height: 100%; margin: 0 auto; position: relative; }
  section.banner div.left > img { display: block; }
  section.banner div.distributor { display: flex; align-items: flex-end; position: absolute; bottom: 0; right: -250px; }
  section.banner div.distributor h6 { margin: 0 10px 0 0; padding: 0; font-size: 16px; color: $primary; font-weight: 600; }
  section.banner div.distributor img { display: block; margin: 0 0 -7px 10px; }
  section.banner div.banner { width: 1342px; height: 611px; background: url('../../public/img/webp/banner_rodo.webp') no-repeat 50% 50%; border-top-left-radius: 500px; border-bottom-left-radius: 500px; transition: 2s; }

  section.rodo-links { display: flex; flex-wrap: wrap; justify-content: center; }
  section.rodo-links a.link { display: flex; flex-direction: column; justify-content: center; align-items: center; width: 250px; height: 250px; margin: 100px; border-radius: 50%; text-decoration: none; }
  section.rodo-links a.link:nth-child(1) { background: $red; }
  section.rodo-links a.link:nth-child(2) { background: $primary; }
  section.rodo-links a.link div.icon { width: 80px; height: 80px; margin: 0 0 5px 0; background: url('../../public/img/icon_rodo.png') no-repeat 50% 50%; }
  section.rodo-links a.link h6 { width: 200px; margin: 5px 0 0 0; padding: 0; font-size: 12px; color: #fff; font-weight: 500; text-align: center; line-height: 20px; }
  section.rodo-links a.link:hover h6 { text-decoration: underline; }

  @media (max-width: 1800px) {
    section.banner div.distributor { right: 0; }
  }

  @media (max-width: 1700px) {
    section.banner div.left > img { display: none; }
    section.banner div.distributor { display: none; }
  }

  @media (max-width: 1342px) {
    section.banner div.banner { width: 100%; border-radius: 0; transition: 0s; }
  }

  @media (max-width: 1000px) {
  section.banner { height: 400px; margin: 0 0 1px 0; }
  section.banner div.banner { height: 100%; transition: 0s; }
  section.banner div.banner { background: url('../../public/img/webp/banner_rodo_medium.webp') no-repeat 50% 50%; }
  }

  @media (max-width: 900px) {
    section.rodo-links a.link { margin: 20px; }
  }

  @media (max-width: 600px) {
    section.banner { height: 200px; }
  }

  @media (max-width: 500px) {
    section.banner div.banner { background: url('../../public/img/webp/banner_rodo_small.webp') no-repeat 50% 50%; }
  }
</style>
