import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vue2Editor from "vue2-editor";
import VueSanitize from "vue-sanitize";
import VueToggles from 'vue-toggles';
import pageContent from './mixins/pageContent';

const defaultOptions = {
  // allowedTags: ['a', 'b', 'br', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'i', 'u', 'strong', 'ul', 'li', 'img'],
  // allowedAttributes: {
  //   'a': [ 'href' ],
  // },
  allowedTags: false,
  allowedAttributes: false,
  disallowedTagsMode: 'discard'
};

Vue.use(VueAxios, axios)
Vue.use(Vue2Editor);
Vue.use(VueSanitize, defaultOptions);
Vue.component('VueToggles', VueToggles);

import Particles from "particles.vue";
import VueMeta from 'vue-meta'

Vue.use(Particles);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

Vue.config.productionTip = false

Vue.mixin(pageContent);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
