<template>
  <div class="admin-login">
    <header>
      <h2>AFK CMS 1.0 STANDARD</h2>
    </header>
    <div id="login-box">
      <h3>{{ sectionHeader }}</h3>
      <section class="login" v-if="section == 0">
        <input type="text" placeholder="Email" v-model="email" @keyup.enter="login()">
        <input type="password" placeholder="Hasło" v-model="password" @keyup.enter="login()">
        <button class="admin-default" @click="login()">Zaloguj</button>
      </section>
      <section class="forgot" v-if="section == 1">
        <input type="text" placeholder="Email" v-model="email" @keyup.enter="changePassword()">
        <button class="admin-default" @click="changePassword()">Przypomnij hasło</button>
      </section>
      <div>
        <button class="text" @click="changeSection()">{{ sectionButton }}</button>
      </div>
    </div>
    <footer>
      <h6>Copyright &copy; AFK CMS. Wszelkie prawa zastrzeżone. Wersja 1.0 Standard</h6>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'AdminLogin',
  data(){
    return{
      section: 0,
      sectionHeader: 'Logowanie',
      sectionHeaderText: ['Logowanie', 'Przypomnij hasło'],
      sectionButton: 'Nie pamiętam hasła',
      sectionButtonText: ['Nie pamiętam hasła', 'Logowanie'],
      email: '',
      password: '',
    }
  },
  computed:{
    api(){
      return this.$store.state.api;
    },
  },
  created(){
    if((typeof localStorage.accessToken != 'undefined' || typeof localStorage.refreshToken != 'undefined')){
      this.$router.push({ name: 'AdminWrapper' });
    }
  },
  methods: {
    changeSection(){
      if(this.section == 0){
        this.section = 1;
        this.sectionHeader = this.sectionHeaderText[1];
        this.sectionButton = this.sectionButtonText[1];
      }else{
        this.section = 0;
        this.sectionHeader = this.sectionHeaderText[0];
        this.sectionButton = this.sectionButtonText[0];
      }
    },
    login(){
      let self = this;
      this.axios
      .post(this.api + 'admin/login',  { email: this.email, password: this.password }, { headers: { notoken: 1 }})
      .then(response => {
        console.log(response.data)
        if(response.data.status == 1){
          if(typeof(localStorage.accessToken) !== "undefined") {
            localStorage.setItem("accessToken", response.data.accessToken);
          }else{
            localStorage.accessToken = response.data.accessToken;
          }
          if(typeof(localStorage.accessToken) !== "undefined") {
            localStorage.setItem("refreshToken", response.data.refreshToken);
          }else{
            localStorage.refreshToken = response.data.refreshToken;
          }
          self.$router.push({ name: 'AdminWrapper' });
        }else{
          self.$store.commit('toggleAlert', {
            visibility: 1,
            type: response.data.status,
            msg: response.data.msg
          });
        }
      });
    },
    changePassword(){
      let self = this;
      this.axios
      .put(this.api + 'admin/forgotten',  { email: this.email }, { headers: { notoken: 1 }})
      .then(response => {
        self.$store.commit('toggleAlert', {
          visibility: 1,
          type: response.data.status,
          msg: response.data.msg
        });
      });
    }
  }
}
</script>

<style scoped lang="scss">
  div.admin-login { display: flex; flex-direction: column; justify-content: space-between; align-items: center; min-height: 100vh; background: rgb(33,147,176); background: linear-gradient(135deg, rgba(33,147,176,1) 0%, rgba(109,213,237,1) 100%); overflow: auto; }

  header { width: 100%; }
  header h2 { margin: 0; padding: 10px; font-weight: 300; font-size: 16px; color: rgba(255, 255, 255, 0.2); letter-spacing: 2px; }

  div#login-box { width: 350px; background: #fff; box-shadow: 0 0 5px #E0E0E0; border-radius: 3px; }
  div#login-box h3 { margin: 40px 0; padding: 0; text-align: center; font-weight: 300; font-size: 30px; color: #424242; }
  div#login-box input { display: block; width: 250px; height: 35px; margin: 20px auto; padding: 5px 0; outline: 0; border: 0; border-bottom: 1px solid #E0E0E0; font-size: 13px; font-weight: 400; color: rgb(33,147,176); }
  div#login-box input::placeholder { color: #BDBDBD; }
  div#login-box input:focus { outline: 0; border-bottom: 1px solid rgb(33,147,176); }
  div#login-box button.admin-default { display: block; width: 250px; height: 35px; margin: 40px auto 30px auto; border: 0; outline: 0; background: rgb(33,147,176); background: linear-gradient(135deg, rgba(33,147,176,1) 0%, rgba(109,213,237,1) 100%);
    color: #fff; box-shadow: 0 0 5px rgb(33,147,176); cursor: pointer; }
  div#login-box button.admin-default:hover { box-shadow: 0 0 10px rgb(33,147,176); }
  div#login-box div { width: 250px; margin: 30px auto; text-align: right;}
  div#login-box button.text { display: inline-block; outline: 0; border: 0; background: none; color: rgb(33,147,176); font-weight: 400; cursor: pointer; font-size: 12px; }
  div#login-box button.text:hover { text-decoration: underline; }

  footer h6 { margin: 0; padding: 10px; color: rgba(255, 255, 255, 0.3); font-weight: 400; font-size: 13px; text-align: center; }
</style>
