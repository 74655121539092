<template>
  <div>
    <div class="element">
      <header :class="{ 'admin-teal': !errors.name, 'admin-red': errors.name }">
        <h4>Nazwa</h4>
      </header>
      <input class="admin-default2" type="text" v-model="newProduct.name">
      <p class="error">{{ errors.name }}</p>
    </div>
    <div class="element">
      <header :class="{ 'admin-teal': !errors.description, 'admin-red': errors.description }">
        <h4>Opis produktu</h4>
      </header>
      <div class="editor">
      <vue-editor :editor-toolbar="editorToolbars['post']" v-model="newProduct.description"/>
    </div>
      <p class="error">{{ errors.description }}</p>
    </div>
    <div class="element">
      <header class="admin-teal">
        <h4>Aktualne zdjęcie</h4>
      </header>
      <div class="thumbnail" v-if="fileView">
        <img :src="fileView" alt="Thumbnail">
      </div>
    </div>
    <div class="element flex">
      <button :class="{ 'admin-default teal': !errors.photo, 'admin-default red': errors.photo }" @click="clickUpload()">Wybierz zdjęcie</button>
      <input id="file" name="attachment" type="file" ref="file" style="display: none" @change="handleFileUpload()">
      <div class="photo" v-if="file != 0">
        <h6>Wybrane zdjęcie: </h6>
        <p>{{ file[0].name }}</p>
      </div>
      <div class="photo" v-if="file != 0">
        <h6>Rozmiar: </h6>
        <p>{{ getMB(file[0].size) }}</p>
      </div>
    </div>
    <p class="error">{{ errors.photo }}</p>
    <div class="element">
      <header :class="{ 'admin-teal': !errors.manual, 'admin-red': errors.manual }">
        <h4>Nazwa pliku z instrukcją (razem z rozszerzeniem)</h4>
        <h5>Plik należy umieścić na serwerze w folderze x poprzez ftp</h5>
      </header>
      <input class="admin-default2" type="text" v-model="newProduct.manual">
      <p class="error">{{ errors.manual }}</p>
    </div>
    <div class="element">
      <header :class="{ 'admin-teal': !errors.catalog, 'admin-red': errors.catalog }">
        <h4>Nazwa pliku z kartą katalogową (razem z rozszerzeniem)</h4>
        <h5>Plik należy umieścić na serwerze w folderze x poprzez ftp</h5>
      </header>
      <input class="admin-default2" type="text" v-model="newProduct.catalog">
      <p class="error">{{ errors.catalog }}</p>
    </div>
    <div class="element element-category">
      <h6>Kategorie</h6>
      <div class="categories">
        <div class="category" :class="{ checked: category.checked == 1 }" v-for="(category, index) in categories" :key="index" @click="checkCategory(index)">
          <p>{{ category.name }}</p>
          <i class="material-icons">{{ getCategoryIcon(index) }}</i>
        </div>
      </div>
      <p class="error">{{ errors.categories }}</p>
    </div>
    <button class="admin-default teal" @click="sendProduct()">dodaj</button>
  </div>
</template>

<script>
import roundTo from 'round-to';

export default {
  name: 'AddProduct',
  data() {
    return {
      file: 0,
      fileView: 0,
      formData: 0,
      categories: [],
      newProduct: {
        name: '',
        description: '',
        manual: '',
        catalog: '',
        categories: [],
      },
      errors: {
        name: '',
        description: '',
        photo: '',
        manual: '',
        catalog: '',
        categories: '',
      },
    }
  },
  computed:{
    api(){
      return this.$store.state.api;
    },
    editorToolbars(){
      return this.$store.state.editorToolbars;
    },
  },
  created(){
    this.getCategories();
  },
  methods: {
    getCategories() {
      let self = this;
      this.axios
      .get(this.api + 'product/category', )
      .then(response => {
        if(response.data.status == 1){
          response.data.data.forEach(data => {
            self.categories.push({
              checked: 0,
              name: data.name,
              _id: data._id
            });
          });
        }
      });
    },
    getCategoryIcon(index){
      if(this.categories[index].checked == 1){
        return 'done'
      }else{
        return 'more_horiz'
      }
    },
    checkCategory(index){
      if(this.categories[index].checked == 1){
        const newProductIndex = this.newProduct.categories.indexOf(this.categories[index]._id);
        this.newProduct.categories.splice(newProductIndex, 1);
        this.categories[index].checked = 0;
      }else{
        this.newProduct.categories.push(this.categories[index]._id);
        this.categories[index].checked = 1;
      }
    },
    async sendProduct() {
      this.formData = new FormData();
      this.formData.append('photo', this.file[0]);
      this.formData.append('name', this.newProduct.name);
      this.formData.append('description', this.newProduct.description);
      this.formData.append('manual', this.newProduct.manual);
      this.formData.append('catalog', this.newProduct.catalog);
      for(let i = 0; i < this.newProduct.categories.length; i++) {
        this.formData.append('categories[]', this.newProduct.categories[i]);
      };
      Object.keys(this.errors).forEach(key => {
        this.errors[key] = '';
      });
      await this.axios
      .post(this.api + 'product/', this.formData, { headers: { 'content-type': `multipart/form-data; boundary=${ this.formData._boundary }` } })
      .then(response => {
        if(response.data.status === 1) {
          this.$store.commit('toggleAlert', {
            visibility: 1,
            type: 1,
            msg: response.data.msg
          });
          this.$router.push({ name: 'Products' });
        } else {
          if(response.data.msgs) {
            response.data.fields.forEach(field => {
              this.errors[field] = response.data.msgs[response.data.fields.indexOf(field)];
            });
          } else {
            this.$store.commit('toggleAlert', {
              visibility: 1,
              type: 0,
              msg: response.data.msg
            });
          };
        };
      })
      .catch(err => {
        this.$store.commit('toggleAlert', {
          visibility: 1,
          type: 0,
          msg: 'Coś poszło nie tak podczas wysyłania zawartości. Sprawdź czy wybrane zdjęcie nie zostało przeniesione lub usunięte.'
        });
      });
    },
    getMB(size){
      return roundTo(size / 1000000, 3) + ' MB';
    },
    clickUpload(){
      document.getElementById('file').click()
    },
    handleFileUpload(){
      if(this.$refs.file.files.length > 0) {
        this.file = this.$refs.file.files;
        this.fileView = URL.createObjectURL(this.file[0]);
      };
    },
  },
}
</script>

<style scoped lang="scss">
  div.element { margin: 20px 0; }
  div.element.photo { width: 100%; }
  div.element.flex { display: flex; align-items: center; margin: 20px 10px; flex-wrap: wrap; }
  div.element.box { margin: 20px; }
  div.element button { margin: 0 10px; }
  div.element.flex button { margin: 5px 10px; }
  div.element.flex div.photo { margin: 5px 10px; }
  div.element header { display: flex; flex-direction: column; align-items: flex-start; justify-content: center; }
  div.element header h5 { margin: 0 10px; padding: 0; font-size: 12px; font-weight: 400; color: #fff; }

  div.element-category { margin: 10px; }
  div.categories { display: flex; flex-wrap: wrap; margin: 0 5px; }
  div.categories div.category { display: flex; align-items: center; height: 35px; margin: 5px; background: $adminGrayLight; border-radius: 3px; cursor: pointer; }
  div.categories div.category.checked { background: $adminGreenLight; }
  div.categories div.category p {  margin: 0 0 0 10px; padding: 0; font-size: 14px; color: #fff; }
  div.categories div.category i.material-icons { margin: 0 10px; font-size: 14px; color: #fff; }

  div h6 { margin: 10px; padding: 0; font-weight: 400; font-size: 14px; color: $adminGrayLight; }
  div.photo { display: flex; align-items: center; background: #fff; height: 35px; margin: 0 10px; }
  div.photo h6 { margin: 0 10px; padding: 0; font-weight: 400; font-size: 14px; color: $adminGrayLight; }
  div.photo p { margin: 0 10px 0 0; padding: 0; font-weight: 400; font-size: 14px; color: $adminBlackLight; }
  div.editor { margin: 0 20px; background: #fff; }

  p.error {
    color: $adminRedLight;
    font-size: 13px;
    margin: 5px 20px;
  }

  div.thumbnail { height: 200px; margin: 20px; position: relative; }
  div.thumbnail img { max-height: 200px; position: absolute; top: 0; left: 0; border-radius: 3px; }

  button { margin: 0 20px 20px 20px; }
</style>
