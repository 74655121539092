<template>
  <header class="admin-the-header">
    <nav>
      <ul>
        <li v-if="!adminNavMobile" @click="toggleAdminNavMobile(1)">
          <button><i class="material-icons">menu</i></button>
        </li>
        <li v-if="adminNavMobile" @click="toggleAdminNavMobile(0)">
          <button><i class="material-icons">close</i></button>
        </li>
      </ul>
    </nav>
    <div class="logo">
      <h2>AFK CMS</h2>
    </div>
    <nav>
      <ul>
        <li>
          <router-link :to="{ name: 'AdminSettings' }" :class="{ active: checkRoute('AdminSettings') }"><i class="material-icons">settings</i></router-link>
        </li>
        <li>
          <button @click="logout()"><i class="material-icons">exit_to_app</i></button>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'AdminTheHeader',
  data(){
    return{
      activeRoute: this.$route.name,
    }
  },
  computed:{
    api(){
      return this.$store.state.api;
    },
    adminNavMobile(){
      return this.$store.state.adminNavMobile;
    },
  },
  watch:{
    $route(to, from){
      this.activeRoute = this.$route.name;
    }
  },
  methods: {
    toggleAdminNavMobile(value){
      this.$store.commit('toggleAdminNavMobile', value);
      if(value){
        document.body.style = "overflow-y: hidden";
      }else{
        document.body.style = "overflow-y: overlay";
      }
    },
    checkRoute(value){
      if(this.activeRoute.search(value) != -1){
        return true;
      }else{
        return false;
      }
    },
    logout(){
      let self = this;
      this.axios
      .put(this.api + 'admin/logout', {})
      .then(response => {
      }).then(function () {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        self.$router.push('/admin')
      });
    },
  }
}
</script>
<style scoped lang="scss">
  header.admin-the-header { display: flex; height: 45px; background: rgb(33,147,176); background: linear-gradient(135deg, rgba(33,147,176,1) 0%, rgba(109,213,237,1) 100%); box-shadow: 0 0 15px #9E9E9E; }

  h2 { height: 45px; margin: 0; padding: 0 20px; line-height: 45px; font-weight: 400; font-size: 15px; color: #fff;  }

  nav:nth-child(1) { display: none; }
  nav:nth-child(3) { margin-left: auto; }

  nav ul { display: flex; margin: 0; padding: 0; list-style: none; }
  nav ul li a, nav ul li button { display: flex; justify-content: center; align-items: center; width: 45px; height: 45px; border: 0; outline: 0; background: 0; text-decoration: none; cursor: pointer; }
  nav ul li:hover a, nav ul li:hover button, nav ul li a.active { background: rgba(255, 255, 255, 0.2); }
  nav ul li a i.material-icons, nav ul li button i.material-icons { color: #fff; font-size: 21px; }

  @media (max-width: 1000px) {
    header.admin-the-header { height: 55px; }
    header.admin-the-header h2 { height: 55px; line-height: 55px; }
    nav:nth-child(1) { display: block; }
    nav ul li a, nav ul li button { width: 55px; height: 55px;  }
  }
</style>
