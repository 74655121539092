<template>
  <nav class="admin-the-nav">
     <section>
      <h5>Główne</h5>
      <ul>
        <li>
          <router-link to="/">
            <i class="material-icons">home</i>
            <p>Strona główna</p>
          </router-link>
        </li>
      </ul>
    </section>
    <section>
      <h5>edycja</h5>
      <ul>
        <li>
          <router-link :to="{ name: 'AdminContent' }" :class="{ active: checkRoute(['AdminContent']) }">
            <i class="material-icons">edit</i>
            <p>Treści</p>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Products' }" :class="{ active: checkRoute(['AdminProducts', 'Products', 'Product', 'EditProduct', 'AddProduct']) }">
            <i class="material-icons">view_in_ar</i>
            <p>Produkty</p>
          </router-link>
        </li>
      </ul>
    </section>
    <section>
      <h5>konto</h5>
      <ul>
        <li>
          <router-link :to="{ name: 'AdminSettings' }" :class="{ active: checkRoute(['AdminSettings']) }">
            <i class="material-icons">settings</i>
            <p>Ustawienia</p>
          </router-link>
        </li>
      </ul>
    </section>
  </nav>
</template>

<script>
export default {
  name: 'AdminTheNav',
  data(){
    return{
      activeRoute: this.$route.name,
    }
  },
  watch:{
    $route(to, from){
      this.activeRoute = this.$route.name;
    }
  },
  methods: {
    checkRoute(values){
      let response = false;
      values.forEach(value => {
        if(this.activeRoute.search(value) != -1){
          response = true;
        };
      });
      return response;
    }
  }
}
</script>
<style scoped lang="scss">
  nav.admin-the-nav { width: 250px; height: calc(100vh - 45px); background: #fff; box-shadow: 0 15px 15px #9E9E9E; }

  section { margin: 30px 0; }
  section h5 { margin: 15px 20px; padding: 0; text-transform: uppercase; font-weight: 500; color: $adminBlackLight; font-size: 13px; letter-spacing: 0.5px; }
  section ul { margin: 0; padding: 0; list-style: none; }
  section li a { display: flex; align-items: center; height: 45px; text-decoration: none; border-top: 1px solid rgba(0, 0, 0, 0.04); }
  section li:last-child a { border-bottom: 1px solid rgba(0, 0, 0, 0.04);  }
  section li a i.material-icons { display: block; width: 21px; height: 21px; margin: 0 10px 0 20px; line-height: 21px; text-align: center; font-size: 16px; color: $adminGrayDark; transform: rotate(0.03deg); }
  section li a p { margin: 0; padding: 0; font-size: 14px; color: $adminGrayDark; font-weight: 400; letter-spacing: 0.5px; }

  section li a:hover, section li a.active { border-left: 3px solid rgb(33,147,176); background: rgba(0, 0, 0, 0.03); }
  section li a:hover i.material-icons, section li a.active i.material-icons { color: rgb(33,147,176); margin: 0 10px 0 17px; }
  section li a:hover p, section li a.active p { color: rgb(33,147,176); font-weight: 500; }

  @media (max-width: 1000px) {
     nav.admin-the-nav { display: none; }
  }


</style>
