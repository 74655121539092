<template>
  <div class="wrapper">
    <div class="wrapper-top">
    <the-header></the-header>
    <router-view/>
    <the-footer></the-footer>
    <the-nav-mobile></the-nav-mobile>
    </div>
    <div class="particles" v-if="content != 0 && particlesReload == 1">
      <Particles id="particles1" :particlesInit="particlesInit" :particlesLoaded="particlesLoaded" :options="particles" />
    </div>
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader.vue'
import TheNavMobile from '@/components/TheNavMobile.vue'
import TheFooter from '@/components/TheFooter.vue'

export default {
  name: 'Home',
  components: {
    TheHeader,
    TheNavMobile,
    TheFooter
  },
  data(){
    return{
      particlesInit: 0,
      particlesLoaded: 0,
      particlesReload: 1,
    }
  },
  computed: {
    particles() {
      return this.$store.state.particles;
    },
    content(){
      return this.$store.state.content;
    },
  },
  watch: {
    $route(to, from){
      let self = this;
      this.particlesReload = 0;
      setTimeout(function(){ self.particlesReload = 1; }, 500);
    },
  }
}
</script>

<style lang="scss" scoped>
  div.wrapper { position: relative; }
  div.wrapper-top { position: relative; z-index: 2; overflow: hidden; }
  @media (max-width: 1000px) {
    div.particles { display: none; }
  }
</style>
