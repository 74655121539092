<template>
  <div class="admin-blog-post">
    <nav class="admin-the-sub-nav">
      <ul>
        <li v-for="(page, index) in subNav" :key="index + 'subnav'">
          <router-link :to="(index === 0) ? { name: 'Products' } : { name: 'AddProduct' }"><button :class="{ active: checkRoute() === index }" v-if="index != 2 || checkRoute() === 2">{{ page }}</button></router-link>
        </li>
      </ul>
    </nav>
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'AdminProductWrapper',
  data(){
    return{
      subNav: ['produkty', 'nowy produkt', 'edytuj produkt'],
      activePage: 0,
      activeRoute: this.$route.name,
      routes: ['Products', 'AddProduct', 'EditProduct'],
    }
  },
  computed:{
    api(){
      return this.$store.state.api;
    },
    editorToolbars(){
      return this.$store.state.editorToolbars;
    },
  },
  watch: {
    $route(to, from){
      this.activeRoute = this.$route.name;
    },
  },
  methods: {
    checkRoute(){
      return this.routes.indexOf(this.activeRoute);
    },
  }
}
</script>

<style scoped lang="scss">
  div.admin-blog-post-list { display: flex; flex-wrap: wrap; }

  div.element { margin: 20px 0; }
  div.element.flex { display: flex; align-items: center; margin: 20px 10px; }
  div.element.box { margin: 20px; }
  div.element button { margin: 0 10px; }

  div.editor { margin: 0 20px; background: #fff; }
  div.photo { display: flex; align-items: center; background: #fff; height: 35px; margin: 0 10px; }
  div.photo h6 { margin: 0 10px; padding: 0; font-weight: 400; font-size: 14px; color: $adminGrayLight; }
  div.photo p { margin: 0 10px 0 0; padding: 0; font-weight: 400; font-size: 14px; color: $adminBlackLight; }
  div h6 { margin: 10px; padding: 0; font-weight: 400; font-size: 14px; color: $adminGrayLight; }

  div.element-category { margin: 10px; }
  div.categories { display: flex; flex-wrap: wrap; margin: 0 5px; }
  div.categories div.category { display: flex; align-items: center; height: 35px; margin: 5px; background: $adminGrayLight; border-radius: 3px; cursor: pointer; }
  div.categories div.category.checked { background: $adminGreenLight; }
  div.categories div.category p {  margin: 0 0 0 10px; padding: 0; font-size: 14px; color: #fff; }
  div.categories div.category i.material-icons { margin: 0 10px; font-size: 14px; color: #fff; }

  div.element-tag { margin: 10px; }
  div.tags { display: flex; flex-wrap: wrap; }
  div.tags div.tag { display: flex; margin: 5px; }
  div.tags div.tag div.tag-done { display: flex; align-items: center; height: 30px; border-radius: 15px; background: #fff; }
  div.tags div.tag div.tag-done p { margin: 0 0 0 10px; padding: 0; font-size: 14px; color: $adminGrayLight; }
  div.tags div.tag div.tag-done i.material-icons { display: flex; justify-content: center; align-items: center; width: 16px; height: 16px; margin: 0 10px 0 5px; border-radius: 50%; font-size: 14px; color: $adminGrayLight; cursor: pointer; }
  div.tags div.tag div.tag-done i.material-icons:hover { background: $adminGrayLight; color: #fff; }
  div.tags div.tag input { width: 200px; margin: 0; height: 30px; border-radius: 15px; font-size: 14px; }

  div.element-visible { margin: 10px; }

  button { margin: 0 20px 20px 20px; }
  div.admin-blog-post a {
    all: initial;
    text-decoration: none;
  }
  div.admin-blog-post a:hover {
    all: initial;
  }
  div.admin-blog-post ul li button { margin: 0; }
  div.admin-blog-post ul {
    margin-top: 20px;
  }
  div.admin-blog-post ul li {
    margin-right: 15px;
  }

  @media (max-width: 400px) {
    div.admin-blog-post ul li button { margin-top: 15px; }
  }

</style>
