<template>
  <div class="offer" v-if="content">
    <section>
      <header>
        <div class="container">
          <div class="img icon_home_1"></div>
          <h4>ochrona twojego domu</h4>
        </div>
      </header>
      <article>
        <img src="../../public/img/webp/offer_1.webp" alt="Ochrona twojego domu">
        <div class="content">
          <p v-html="$decodeHTML($sanitize($getContent(page, 'Artykuł 1', 'Artykuł 1', 'treść')))"></p>
        </div>
      </article>
    </section>
    <section id="ochrona_techniczna_osob_i_mienia">
      <header>
        <div class="container">
          <div class="img icon_home_2"></div>
          <h4>ochrona techniczna osób i mienia</h4>
        </div>
      </header>
      <article>
        <img src="../../public/img/webp/offer_2.webp" alt="Ochrona techniczna osób i mienia">
        <div
          class="content"
          v-for="index in articleDataCount.article2"
          :key="'article2' + index"
        >
          <header>
            <button @click="toggleExtend(index - 1)">
              <h5 v-html="$decodeHTML($sanitize($getContent(page, 'Artykuł 2', 'Artykuł 2', `tytuł ${ index }`)))"></h5>
              <i class="material-icons">{{ getIcon(extendArray[index - 1].extend) }}</i>
            </button>
          </header>
          <expand>
            <div
              v-if="extendArray[index - 1].extend"
              :style="articleAnimationProperties"
            >
              <p v-html="$decodeHTML($sanitize($getContent(page, 'Artykuł 2', 'Artykuł 2', `treść ${ index }`)))"></p>
            </div>
          </expand>
        </div>
      </article>
    </section>
    <section id="energooszczedne_technologie">
      <header>
        <div class="container">
          <div class="img icon_home_3"></div>
          <h4>energooszczędne technologie</h4>
        </div>
      </header>
      <article>
        <img src="../../public/img/webp/offer_3.webp" alt="Energooszczędne technologie">
        <div
          class="content"
          v-for="index in articleDataCount.article3"
          :key="'article3' + index"
        >
          <header>
            <button @click="toggleExtend(index + 7)">
              <h5 v-html="$decodeHTML($sanitize($getContent(page, 'Artykuł 3', 'Artykuł 3', `tytuł ${ index }`)))"></h5>
              <i class="material-icons">{{ getIcon(extendArray[index + 7].extend) }}</i>
            </button>
          </header>
          <expand>
            <div
              v-if="extendArray[index + 7].extend"
              :style="articleAnimationProperties"
            >
              <p v-html="$decodeHTML($sanitize($getContent(page, 'Artykuł 3', 'Artykuł 3', `treść ${ index }`)))"></p>
            </div>
          </expand>
        </div>
      </article>
    </section>
    <section id="audyt_bezpieczenstwa">
      <header>
        <div class="container">
          <div class="img icon_home_4"></div>
          <h4>Audyt bezpieczeństwa</h4>
        </div>
      </header>
      <article>
        <img src="../../public/img/webp/offer_4.webp" alt="Audyt bezpieczeństwa">
        <div class="content">
          <p v-html="$decodeHTML($sanitize($getContent(page, 'Artykuł 4', 'Artykuł 4', 'treść')))"></p>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
import Expand from '../components/animations/Expand.vue';

export default {
  name: 'Offer',
  components: {
    Expand,
  },
  data(){
    return{
      page: 'Oferta',
      extendCount: 13,
      extendArray: [],
      articleDataCount: {
        article2: 8,
        article3: 5,
      },
      articleAnimationProperties: {
        transitionDuration: .5 + 's',
        transitionTimingFunction: 'ease-out',
      }
    }
  },
  created(){
    for(let i = 0; i < this.extendCount; i++){
      this.extendArray.push({ extend: 0 });
    };
  },
  computed: {
    content() {
      return this.$store.state.content;
    }
  },
  methods: {
    getIcon(type){
      if(type){
        return 'keyboard_arrow_up'
      }else{
        return 'keyboard_arrow_down'
      }
    },
    toggleExtend(index){
      if(this.extendArray[index].extend){
        this.extendArray[index].extend = 0;
      }else{
        this.extendArray[index].extend = 1;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  section { display: flex; flex-wrap: wrap; }

  section > header { display: flex; justify-content: flex-end; align-items: center; width: 300px; height: 200px; margin: 20px 0; border-top-right-radius: 100px; border-bottom-right-radius: 100px; background: $gray; }
  section > header div.container { width: 120px; height: 120px; margin-right: 50px; }
  section > header div.container div.img { display: block; width: 100%; height: 60px; margin: 0 auto; }
  section > header div.container div.img.icon_home_1 { background: url('../../public/img/icon_home_1.png') no-repeat 50% 50%; }
  section > header div.container div.img.icon_home_2 { background: url('../../public/img/icon_home_2.png') no-repeat 50% 50%; }
  section > header div.container div.img.icon_home_3 { background: url('../../public/img/icon_home_3.png') no-repeat 50% 50%; }
  section > header div.container div.img.icon_home_4 { background: url('../../public/img/icon_home_4.png') no-repeat 50% 50%; }
  section > header div.container h4 { margin: 15px 0 0 0; padding: 0; font-size: 10px; line-height: 18px; text-transform: uppercase; text-align: center; font-weight: 900; color: $primary; }

  section { margin-bottom: 20px; }
  section article { width: 1200px;  margin: 20px 0 0 50px; }
  section article img { display: block; margin-bottom: 20px; }
  section article div.content header { display: flex; align-items: center; }
  section article div.content header h5 { margin: 0; padding: 0; text-transform: uppercase; font-size: 18px; color: $primary; font-weight: 700; text-align: left; }
  section article div.content header button { display: flex; align-items: center; margin: 0; padding: 0; background: none; border: none; outline: none; cursor: pointer; }
  section article div.content header button i.material-icons { color: $primary; font-size: 32px; }
  section article div.content p { margin: 20px 0 0 0; color: $primary; font-size: 15px; font-weight: 500; line-height: 18px; text-align: justify; }
  section article div.content p:last-child { margin-bottom: 20px; }

  @media (max-width: 1550px) {
    section article { margin: 20px auto; }
  }

  @media (max-width: 1240px) {
    section article { width: calc(100vw - 40px); }
    section article img { width: calc(100vw - 40px); }
  }

  @media (max-width: 500px) {
    section article div.content header button { width: 100%; justify-content: space-between; }
  }
</style>
