<template>
  <div class="admin-products">
    <router-link
      :to="{ name: 'EditProduct', params: { productId: product._id } }"
      v-for="product in products"
      :key="'id' + product._id"
    >
      <product v-bind:data="product"></product>
    </router-link>
  </div>
</template>

<script>
import Product from "@/components/admin/products/Product.vue";

export default {
  name: "Products",
  components: {
    Product,
  },
  data() {
    return {
      products: [],
    };
  },
  computed: {
    api() {
      return this.$store.state.api;
    },
  },
  async created() {
    await this.getProducts();
  },
  methods: {
    async getProducts() {
      let self = this;
      await this.axios.get(this.api + "product").then((response) => {
        if (response.data.status == 1) {
          self.products = response.data.data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
div.admin-products {
  display: flex;
  flex-wrap: wrap;
}
div.admin-products a {
  display: block;
  text-decoration: none;
}
</style>
