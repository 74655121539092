<template>
  <header>
    <ul>
      <li :class="{ active: activeRoute == 'Home' }"><router-link to="/">strona główna</router-link></li>
      <li :class="{ active: activeRoute == 'Offer' }"><router-link to="/oferta">oferta</router-link></li>
      <li :class="{ active: activeRoute == 'OfferVisonic' || activeRoute == 'AlarmButtons' || activeRoute == 'Controllers' || activeRoute == 'IntrusionDetectors' 
        || activeRoute == 'Keyboards' || activeRoute == 'SafetySensors' || activeRoute == 'Sirens' || activeRoute == 'VisonicApp' }" >
        <router-link to="/oferta-visonic">oferta visonic</router-link>
      </li>
      <li :class="{ active: activeRoute == 'Contact' }"><router-link to="/kontakt">kontakt</router-link></li>
      <li :class="{ active: activeRoute == 'Rodo' }"><router-link to="/rodo">rodo</router-link></li>
    </ul>
    <router-link to="/"></router-link>
    <div class="menu" @click="toggleNavMobile()">
      <i class="material-icons">{{ menuIcon }}</i>
    </div>
  </header>
</template>

<script>
export default {
  name: 'TheHeader',
  data(){
    return{
      activeRoute: this.$route.name,
      menuIcon: 'menu',
    }
  },
  computed:{
    navMobile(){
      return this.$store.state.navMobile;
    },
  },
  watch: {
    $route(to, from){
      this.activeRoute = this.$route.name;
    },
    navMobile(){
      if(this.navMobile){
        this.menuIcon = 'close';
      }else{
        this.menuIcon = 'menu';
      }
    }
  },
  methods: {
    toggleNavMobile(){
      if(!this.navMobile){
        this.$store.commit('toggleNavMobile', 1);
        document.body.style = "overflow-y: hidden";
      }else{
        this.$store.commit('toggleNavMobile', 0);
        document.body.style = "overflow-y: overlay";
      }
    }
  },
}
</script>
<style scoped lang="scss">
  header { display: flex; align-items: center; margin: 10px; overflow: hidden; }
  ul { display: flex; margin: 0; padding: 0; list-style: none; }
  ul li a { display: inline-block; margin: 0; padding: 20px 20px; color: $secondary; text-decoration: none; text-transform: uppercase; font-size: 13px; font-weight: 500; cursor: pointer; color: $primary; }
  ul li a:hover { font-weight: 600; }
  ul li.active a { color: $primary; font-weight: 600; } 

  header > a { display: none; width: 70px; height: 70px; background: url('../assets/logo_small.png') no-repeat 50% 50%; }
  div.menu { display: none; justify-content: center; align-items: center; width: 70px; height: 70px; }
  div.menu:hover { background: rgba(255, 255, 255, 0.1); transition: .5s; cursor: pointer; }
  div.menu i.material-icons { color: $primary; font-size: 32px; }
  div.menu:hover i.material-icons { color: $secondary; }

  @media (max-width: 1000px) {
    header { justify-content: space-between; margin: 0; }
    header ul { display: none; }

    header > a { display: block; }
    div.menu { display: flex; }
  }
</style>
